import { Attribute, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  GestoresResiduosEstadosControllerService,
  GestoresResiduosControllerService,
  ProvinciaControllerService,
  GestionResiduosFilterDTO,
  PageDTOGestoresResiduosDTO,
} from 'api-rest';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { HoldersService } from '../../../services/holders.service';

@Component({
  selector: 'lib-waste-managers-search',
  templateUrl: './waste-managers-search.component.html',
  styleUrls: ['./waste-managers-search.component.scss'],
})
export class WasteManagersSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  provinciaArray: any[] = [];
  estadoArray: any[] = [];

  headArray: any = [
    {
      Head: 'ID. Gestor de Residuo',
      FieldName: 'id',
    },
    {
      Head: 'Razón Social',
      FieldName: 'razonSocial',
    },
    {
      Head: 'Provincia',
      FieldName: 'provinciaFisica',
      Attribute: 'nombre',
    },
    {
      Head: 'Documento (NIE,NIF)',
      FieldName: 'codigoDocumento',
    },
    {
      Head: 'Estado',
      FieldName: 'gestoresResiduosEstado',
      Attribute: 'descripcion',
    },
    {
      Head: 'NIMA',
      FieldName: 'nima',
    },
    {
      Head: 'Acuerdo con Envalora',
      FieldName: 'acuerdoEnvalora',
      renderValue: (item: any) => (item?.acuerdoEnvalora == true ? 'Si' : 'No'),
    },
    {
      Head: 'Nº Inscripción en registro (1)',
      FieldName: 'numInscripcion1',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Editar',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'baja_gestor',
              name: 'Baja de Gestor',
              show: {
                params: 'canAccessDownManager',
                accion: 'baja_gestor',
              },
            },
          ],
        },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;
  optionArray = [{ value: 'Si' }, { value: 'No' }];

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    private provinciaControllerService: ProvinciaControllerService,
    private gestoresResiduosEstadosControllerService: GestoresResiduosEstadosControllerService,
    private gestoresResiduosControllerService: GestoresResiduosControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private holdersService: HoldersService
  ) {
    this.searchForm = this.formBuilder.group({
      idGestor: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      provincia_fisica: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
      nima: [{ value: null, disabled: false }],
      auto_peligrosidad: [{ value: null, disabled: false }],
      gestores: [{ value: null, disabled: false }],
      acondicionadores: [{ value: null, disabled: false }],
      acuerdoEnv: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.getComboEstados();
    this.getComboProvincia();
  }

  checked(event: any) {}

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.gestoresResiduosControllerService
      .exportCSV3(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_Gestor.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    const backendFilter: any = {
      id: this.searchForm.get('idGestor')?.value,
      codigoDocumento: this.searchForm.get('codigo_documento')?.value,
      razonSocial: this.searchForm.get('razon_social')?.value,
      gestoresResiduosEstado: this.searchForm.get('estado')?.value,
      nima: this.searchForm.get('nima')?.value,
      autorizacionPeligrosos: this.searchForm.get('auto_peligrosidad')?.value,
      gestorResiduos: this.searchForm.get('gestores')?.value,
      acondicionadorEnvase: this.searchForm.get('acondicionadores')?.value,
      acuerdoEnvalora:
        this.searchForm.get('acuerdoEnv')?.value === 'Si'
          ? true
          : this.searchForm.get('acuerdoEnv')?.value === 'No'
          ? false
          : undefined,
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.gestoresResiduosControllerService
      .findAll8(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOGestoresResiduosDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos);
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openDetailShow(element.id);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'baja_gestor') {
      this.darBajaGestor(element);
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el gestor?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarGestor(element);
      }
    });
  }

  darBajaGestor(element?: any) {
    // this.spinnerSrv.loadingShow();
    this.gestoresResiduosControllerService
      .bajaGestorResiduo(element.id)
      .subscribe({
        next: (value: any) => {
          // this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `Al gestor de residuos se le ha dado de baja correctamente.`,
            'success'
          );
          this.searchData();
        },
        error: (err: { message: any }) => {
          // this.spinnerSrv.loadingHide();
        },
      });
  }

  eliminarGestor(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.gestoresResiduosControllerService.deleteById4(element.id).subscribe({
        next: (value: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `El gestor se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar el gestor',
            'error'
          );
        },
      });
    }
  }

  getCnaDescription(cnae: any) {
    return cnae.clase + ' - ' + cnae.descripcion;
  }

  getCnaeObjectQuery(searchterm: string) {
    return {
      descripcion: `or(ci(${searchterm}))`,
      clase: `or(ci(${searchterm}))`,
    };
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  getComboEstados() {
    this.gestoresResiduosEstadosControllerService.findAll9().subscribe({
      next: (data: any) => {
        this.estadoArray = data!;
      },
      error: () => {},
    });
  }

  openDetailShow(id: number): void {
    this.holdersService.setTempHoldersSearch(true);
    this.router.navigate(['/holders/waste-managers-detail', id]);
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('poseedores-gestor', 'view');
    const resultDelete = this.authService.can('poseedores-gestor', 'delete');
    const resultUpdateUnregister = this.authService.can(
      'poseedores-gestor',
      'update-unregister'
    );
    const listPermission = list.map((objeto: any) => {
      const estadoDelete =
        objeto.gestoresResiduosEstado &&
        [1].includes(objeto.gestoresResiduosEstado.id);
      const estadoUpdateUnregister =
        objeto.gestoresResiduosEstado && objeto.gestoresResiduosEstado.id === 2;

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete: resultDelete && estadoDelete,
        canAccessDownManager: resultUpdateUnregister && estadoUpdateUnregister,
      };
    });

    return listPermission;
  }

  getComboProvincia() {
    this.provinciaControllerService
      .listarComunidades1({}, { page: 0, size: 500, sort: [] })
      .subscribe({
        next: (data: any) => {
          this.provinciaArray = data.datos!;
        },
        error: () => {},
      });
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
