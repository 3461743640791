import { Attribute, Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  FacturaEstadoDTO,
  FacturaDTO,
  FacturaPoseedorEstadosControllerService,
  PoseedorControllerService,
  PoseedorFilterDTO,
  FacturaSddrcaControllerService,
  PageDTOFacturaDTO,
  FacturaSDDRCAFilterDTO,
  SolicitudReutilizacionControllerService, UsuarioSDDRCaDTO, 
} from 'api-rest';
import { DocumentService } from '../../../services/document.service';
import moment from 'moment';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DatePipe } from '@angular/common';
import {
  OpenSddrInvoiceNewDocumentDialogComponent
} from "../open-sddr-invoice-document-dialog/open-sddr-invoice-document-dialog.component";
import {zip} from "rxjs";

@Component({
  selector: 'app-open-sddr-self-invoices-search',
  templateUrl: './open-sddr-self-invoices-search.component.html',
  styleUrls: ['./open-sddr-self-invoices-search.component.scss'],
})
export class OpenSddrSelfInvoicesSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  poseedorArray: any[] = [];
  estadoArray: any[] = [];
  usuariosSddr!: UsuarioSDDRCaDTO[];

  updateList: boolean = false;
  listaGestores = this.environment.urlBackCore + 'api/v1/poseedores/gestores-residuos/byUser';
  defaultGestoresParameters = { 'gestoresResiduosEstado.id': 2 };

  headArray: any = [
    {
      Head: 'ID. Usuario',
      FieldName: 'poseedor',
      renderValue: (item: FacturaDTO) => {
        if (item.poseedor)
          return item.poseedor?.id;
        else if (item.adherido)
          return item.adherido?.id;
        else return '';
      }
    },
    {
      Head: 'Usuario SDDR CA',
      FieldName: 'poseedor',
      renderValue: (item: FacturaDTO) => {
        if (item.poseedor)
          return 'PO - ' + item.poseedor?.razonSocial;
        else if (item.adherido)
          return 'AD - ' +item.adherido?.razonSocial;
        else return '';
      }
    },
    {
      Head: 'ID. Gestor',
      FieldName: 'gestoresResiduos',
      Attribute: 'id',
    },
    {
      Head: 'Gestor',
      FieldName: 'gestoresResiduos',
      Attribute: 'razonSocial',
    },
    {
      Head: 'Documento',
      FieldName: 'id',
      renderValue: (item: FacturaDTO) => {
        if (item.poseedor)
          return item.poseedor?.codigoDocumento;
        else if (item.adherido)
          return item.adherido?.codigoDocumento;
        else if (item.gestoresResiduos)
          return item.gestoresResiduos?.codigoDocumento;
        else return '';
      }
    },
    {
      Head: 'Número de prefactura',
      FieldName: 'id',
      renderValue: (item: FacturaDTO) =>
        item.fechaAutofactura !== null ? item.id : '',
    },
    {
      Head: 'Fecha de prefactura',
      FieldName: 'fechaAutofactura',
      renderValue: (item: FacturaDTO) =>
        this.datePipe.transform(item.fechaAutofactura, 'dd/MM/yyyy'),
    },
    {
      Head: 'Número factura ',
      FieldName: 'numeroFactura',
    },
    {
      Head: 'Fecha factura',
      FieldName: 'fechaFactura',
      renderValue: (item: FacturaDTO) =>
        this.datePipe.transform(item.fechaFactura, 'dd/MM/yyyy'),
    },
    {
      Head: 'Estado ',
      FieldName: 'estado',
      renderValue: (item: FacturaDTO) =>
        item.estado?.descripcion
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Eliminar registro',
          show: { params: 'canAccessDelete' },
        },
        {
          nameButton: 'add_invoice',
          icon: 'upload_file',
          toolTip: 'Adjuntar factura',
          show: { params: 'canAccessUpInvoice' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'download_invoice',
              name: 'Descargar factura',
              show: { params: 'canAccessDownloadInvoice' },
            },
            {
              nameButton: 'download_self_invoice',
              name: 'Descargar prefactura',
              show: { params: 'canAccessDownloadSelfInvoice' },
            },
            {
              nameButton: 'accept_invoice',
              name: 'Aceptar factura',
              show: { params: 'canAccessAcceptInvoice' },
            },
          ],
        },
      ],
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService | any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private solicitudReutilizacionCtrSrv: SolicitudReutilizacionControllerService,
    private facturaSddrcaCtrSrv: FacturaSddrcaControllerService,
    private facturaPoseedorEstadosCtrSrv: FacturaPoseedorEstadosControllerService,
    private poseedorControllerService: PoseedorControllerService,
    private downloadService: DownloadService,
    private documentService: DocumentService,
    private snackBarSrv: SnackbarService,
    private datePipe: DatePipe
  ) {
    this.searchForm = this.formBuilder.group({
      usuarioSDDRCA: [{value: null, disabled: false}],
      gestor: [{ value: null, disabled: false }],
      idposeedor: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      idadherido: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      idgestorResiduos: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      numero_autoFactura: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      fecha_autoFactura: [{ value: null, disabled: false }],
      numero_factura: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      fecha_factura: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.spinnerSrv.loadingShow();
    zip(
      this.facturaPoseedorEstadosCtrSrv.findAll11(),
      this.solicitudReutilizacionCtrSrv.findAllUsuariosAsociados()
    ).subscribe({
      next: (responses: any[]) => {
        this.spinnerSrv.loadingHide();
        this.estadoArray = responses[0];
        this.usuariosSddr = responses[1];
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });

    //Solo los SDDR_CA_ENV_GEST y SDDR_CA_ENV_CONS verán todos los campos
    this.headArray = this.canViewResource('SDDR-CA-ENV-GEST') || this.canViewResource('SDDR-CA-ENV-CONS')
      ? this.headArray
      : this.headArray.filter((head: any) => {
        // Verifica el rol del usuario
        if (this.canViewResource('PO-GESTOR')) {
          return head.FieldName !== 'poseedor' && head.FieldName !== 'adherido';
        } else if (this.canViewResource('PO-POSEEDOR') || this.canViewResource('AD-ADHERIDO')) {
          return head.FieldName !== 'gestoresResiduos';
        }
        return;
      });

  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const {backendFilter, page, size} = this.getBackendFilter();
    this.facturaSddrcaCtrSrv
      .exportCSV4(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_Factura_Poseedor.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    const {
      usuarioSDDRCA,
      gestor,
      idposeedor,
      idadherido,
      idgestorResiduos,
      codigo_documento,
      razon_social,
      numero_autoFactura,
      fecha_autoFactura,
      numero_factura,
      fecha_factura,
      estado
    } = this.searchForm.controls;

    const formatDate = (date: any) => {
      if (date && date.value) {
        return moment(new Date(date.value)).format('DD/MM/YYYY');
      }
      return undefined;
    };

    let gestorIds;
    if (this.authService.user?.roleEntities?.length > 0 && this.canViewResource('PO-GESTOR')) {
      gestorIds = this.authService.user?.roleEntities.map(
        (gestorRol: any) => gestorRol.entity as number
      );
    }
    const selectGestores = this.searchForm.get('gestor')?.value;

    const backendFilter: FacturaSDDRCAFilterDTO = {
      poseedor: { id: [] },
      adherido: { id: [] },
      numeroAutofactura: numero_autoFactura?.value,
      fechaAutofactura: formatDate(fecha_autoFactura),
      numeroFactura: numero_factura?.value,
      fechaFactura: formatDate(fecha_factura),
      estado: estado?.value,
      razonSocial: razon_social?.value,
      codigoDocumento: codigo_documento?.value,
      gestoresResiduos: {
        id: gestor.value != null
          ? selectGestores.map((objeto: any) =>
            objeto.id !== undefined ? objeto.id : objeto)
          : idgestorResiduos != null
            ? idgestorResiduos.value
            : gestorIds
      },
    };

    // Añadimos el filtro en idPoseedor o idAdherido según corresponda (múltiple seleccion)
    const selectedValues = usuarioSDDRCA?.value;
    const poseedorIds: number[] = [];
    const adheridoIds: number[] = [];

    // Añadimos valores del combo si hay alguno seleccionado
    if (selectedValues && Array.isArray(selectedValues)) {
      selectedValues.forEach((value: string) => {
        const usuario = this.usuariosSddr.find(
          (x: UsuarioSDDRCaDTO) => (x.id! + x.tipoUsuario!) === value
        );

        if (usuario) {
          if (usuario.tipoUsuario === 'POSEEDOR') {
            poseedorIds.push(usuario.id!);
          } else if (usuario.tipoUsuario === 'ADHERIDO') {
            adheridoIds.push(usuario.id!);
          }
        }
      });
    } else {
      // Valores del input de texto
      const singlePoseedorId = idposeedor.value;
      const singleAdheridoId = idadherido.value;

      if (singlePoseedorId != null) {
        poseedorIds.push(singlePoseedorId);
      }
      if (singleAdheridoId != null) {
        adheridoIds.push(singleAdheridoId);
      }
    }

    if (poseedorIds.length > 0) {
      backendFilter.poseedor = poseedorIds.length > 0 ? { id: poseedorIds } : undefined
    }
    if (adheridoIds.length > 0) {
      backendFilter.adherido = adheridoIds.length > 0 ? { id: adheridoIds } : undefined
    }

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.facturaSddrcaCtrSrv
      .findAll6(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOFacturaDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0;
            this.gridArray = this.canAccessAll(data.datos) ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'add_invoice') {
      this.openModalUploadInvoice(element);
    } else if (element.nameButton == 'download_invoice') {
      this.descargar(element.documento);
    } else if (element.nameButton == 'download_self_invoice') {
      this.descargar(element.documentoAutofactura);
    } else if (element.nameButton == 'accept_invoice') {
      this.confirmacionCambioEstado(element.id, 3);
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la prefactura?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarInvoices(element);
      }
    });
  }

  eliminarInvoices(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.facturaSddrcaCtrSrv.deleteById1(element.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `El registro de factura se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  comboEstados() {
    this.facturaPoseedorEstadosCtrSrv.findAll11().subscribe({
      next: (data: Array<FacturaEstadoDTO>) => {
        this.estadoArray = data!;
      },
      error: () => { },
    });
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  openModalUploadInvoice(element?: any): void {
    const dialogRef = this.dialog.open(OpenSddrInvoiceNewDocumentDialogComponent,
      {
        width: '650px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          itemOpen: element,
        },
        disableClose: true,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchData();
      }
    });
  }

  descargar(documento: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(documento.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  confirmacionCambioEstado(id?: any, estadoId?: any): void {
    Swal.fire({
      text: `¿Desea cambiar el estado del registro por Aceptada por Envalora?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.actualizarEstado(id, estadoId);
      }
    });
  }

  actualizarEstado(id?: any, estadoId?: any) {
    if (id && estadoId) {
      this.spinnerSrv.loadingShow();
      this.facturaSddrcaCtrSrv.actualizarEstado(id, estadoId)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              `El registro de factura se ha actualizado correctamente`,
              'success'
            );
            this.searchData();
          },
          error: (err: { message: any }) => {
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canAccessPoseedorAdherido(): boolean {
    return this.canViewResource('PO-POSEEDOR') || this.canViewResource('AD-ADHERIDO');
  }

  canAccessGestor(): boolean {
    return this.canViewResource('PO-GESTOR');
  }

  canAccessEnvalora(): boolean {
    return this.canViewResource('SDDR-CA-ENV-GEST') || this.canViewResource('SDDR-CA-ENV-CONS');
  }

  canAccessExport(): boolean {
    return this.authService.can('sddr-ca-facturas', 'update-exportar');
  }

  canAccessAll(list: any) {
    const resultDelete = this.authService.can('sddr-ca-facturas', 'delete');
    const resultUpFactura = this.authService.can('sddr-ca-facturas', 'update');
    const resultDownloadAutofactura = this.authService.can('sddr-ca-facturas', 'download-autofactura');
    const resultDownloadFactura = this.authService.can('sddr-ca-facturas', 'download-factura');
    const resultUpdateAccept = this.authService.can('sddr-ca-facturas', 'update-accept');

    const listPermission = list.map((objeto: any) => {
      const estado1 = objeto.estado && objeto.estado.id === 1;
      const estado2 = objeto.estado && objeto.estado.id === 2;
      const estado3 = objeto.estado && objeto.estado.id === 3;

      let formattedDate = objeto.fechaFactura != null
        ? this.datePipe.transform(objeto.fechaFactura, 'dd/MM/yyyy')
        : null;
      objeto.fechaMFactura = formattedDate;

      let formattedDateM = objeto.fechaAutofactura != null
        ? this.datePipe.transform(objeto.fechaAutofactura, 'dd/MM/yyyy')
        : null;
      objeto.fechaMAutofactura = formattedDateM;

      return {
        ...objeto,
        canAccessDelete: resultDelete && estado1,
        canAccessUpInvoice: resultUpFactura && estado1,
        canAccessDownloadInvoice: resultDownloadFactura && (estado2 || estado3),
        canAccessDownloadSelfInvoice: resultDownloadAutofactura,
        canAccessAcceptInvoice: resultUpdateAccept && estado2,
      };
    });

    return listPermission;
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'gestor': {
        return this.searchForm.get('gestor') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  getGestorDescription(obj: any) {
    return obj?.razonSocial;
  }

  getGestorObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm,
      'estado.id': 4,
    };
  }

}
