/**
 * Poseedores API
 * Poseedores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GrupoEnvaseModel } from './grupoEnvaseModel';


export interface TipoEnvasesModel { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    deleted?: string;
    deletedBy?: string;
    id?: number;
    descripcion?: string;
    grupoEnvase?: GrupoEnvaseModel;
    orden?: number;
    tipologia1?: TipoEnvasesModel.Tipologia1Enum;
    tipologia2?: TipoEnvasesModel.Tipologia2Enum;
    tipologia3?: TipoEnvasesModel.Tipologia3Enum;
}
export namespace TipoEnvasesModel {
    export type Tipologia1Enum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const Tipologia1Enum = {
        Primario: 'PRIMARIO' as Tipologia1Enum,
        Secundario: 'SECUNDARIO' as Tipologia1Enum,
        Terciario: 'TERCIARIO' as Tipologia1Enum
    };
    export type Tipologia2Enum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const Tipologia2Enum = {
        Primario: 'PRIMARIO' as Tipologia2Enum,
        Secundario: 'SECUNDARIO' as Tipologia2Enum,
        Terciario: 'TERCIARIO' as Tipologia2Enum
    };
    export type Tipologia3Enum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const Tipologia3Enum = {
        Primario: 'PRIMARIO' as Tipologia3Enum,
        Secundario: 'SECUNDARIO' as Tipologia3Enum,
        Terciario: 'TERCIARIO' as Tipologia3Enum
    };
}


