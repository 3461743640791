<!-- MIGAS DE PAN Y TÍTULO -->
<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Poseedores</li>
          <li class="breadcrumb-item">Prefacturas y facturas</li>
        </ol>
      </nav>
      <div class="content-title">
        <h2 class="title-pag">Búsqueda Prefacturas y facturas</h2>
      </div>
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->
<!-- CONTENIDO -->

<div class="container-form">
  <!-- Formulario de búsqueda -->
  <form class="container form-search py-3" [formGroup]="searchForm">

    <div class="row form">
      <div class="col-sm flex-grow-1" *ngIf="canAccessPoseedorAdherido()">
        <label for="usuarioSDDRCA">Usuario SDDR CA </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="usuarioSDDRCA" id="usuarioSDDRCA" placeholder="-- Usuario SDDR CA --" multiple>
            <mat-option *ngFor="let tipo of usuariosSddr" [value]="tipo.id!+tipo.tipoUsuario!">
              {{ tipo?.tipoUsuario === 'POSEEDOR' ? 'PO' : 'AD' }} -
              {{ tipo?.razonSocial }}
              {{ tipo?.denominacion ? ' - ' + tipo.denominacion : '' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!--      TODO añadir filtro gestorresiduos-->
      <div class="col-sm flex-grow-1" *ngIf="canAccessGestor()">
        <label for="gestor">Gestor </label>
        <div class="custom-mat-field" appearance="outline">
          <lib-ge-select-pagination [endPointFilter]="listaGestores" [multiple]=true [updateList]="updateList"
                                    [placeHolder]="'-- Gestores --'" [formControlNameSelect]="getFormControl('gestor')"
                                    [descripcion]="getGestorDescription" [objQueryName]="getGestorObjectQuery" [customClass]="rightHolder('gestor')"
                                    [defaultParameters]="defaultGestoresParameters">
          </lib-ge-select-pagination>
        </div>
      </div>
      <div class="col-sm flex-grow-1" *ngIf="canAccessPoseedorAdherido() || canAccessGestor()">
        <label for="estado">Estado </label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="estado" id="estado" placeholder="-- Estado --">
              <mat-option *ngFor="let des of estadoArray" [value]="des">
                {{ des.descripcion }}
              </mat-option>
            </mat-select>
            <span *ngIf="showClearButton('estado')" tabindex="0" class="ng-clear-wrapper"
                  (click)="clearSelection($event,'estado')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
          </mat-form-field>
      </div>
      <div class="col-sm flex-grow-1" *ngIf="canAccessEnvalora()">
        <ng-container>
          <label for="idposeedor">ID. Poseedor </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="text" matInput formControlName="idposeedor" id="idposeedor" placeholder="ID. Poseedor"
                     value="" maxlength="10" (keypress)="onlyNumberKey($event)">
            </mat-form-field>
          </div>
        </ng-container>
      </div>
      <div class="col-sm flex-grow-1" *ngIf="canAccessEnvalora()">
        <ng-container>
          <label for="idadherido">ID. Adherido </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="text" matInput formControlName="idadherido" id="idadherido" placeholder="ID. Adherido"
                     value="" maxlength="10" (keypress)="onlyNumberKey($event)">
            </mat-form-field>
          </div>
        </ng-container>
      </div>
      <div class="col-sm flex-grow-1" *ngIf="canAccessEnvalora()">
        <ng-container>
          <label for="idgestorResiduos">ID. Gestor </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="text" matInput formControlName="idgestorResiduos" id="idgestorResiduos" placeholder="ID. Gestor"
                     value="" maxlength="10" (keypress)="onlyNumberKey($event)">
            </mat-form-field>
          </div>
        </ng-container>
      </div>
      <div class="col-sm col-sm flex-grow-1" *ngIf="canAccessEnvalora()">
        <ng-container>
          <label for="codigo_documento">Documento (NIE, NIF) </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="codigo_documento" id="codigo_documento"
                     placeholder="Documento (NIE, NIF)" type="text">
            </mat-form-field>
          </div>
        </ng-container>
      </div>
      <div class="col-sm col-sm flex-grow-1" *ngIf="canAccessEnvalora()">
        <ng-container>
          <label for="razon_social">Razón Social </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="razon_social" id="razon_social" placeholder="Razón Social" type="text">
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="row form">
      <div class="col-sm flex-grow-1">
        <label for="numero_autoFactura">Número de prefactura </label>
        <div class="custom-mat-field">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input matInput formControlName="numero_autoFactura" id="numero_autoFactura"
                   placeholder="Número de prefactura " type="text" maxlength="10" (keydown)="onlyNumberKey($event)">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm flex-grow-1">
        <label for="fecha_autoFactura">Fecha de prefactura </label>
        <div class="custom-mat-field">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input matInput [matDatepicker]="datepicker" formControlName="fecha_autoFactura">
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker>
            </mat-datepicker>
            <mat-error *ngIf="controlHasError('fecha_autoFactura', 'matDatepickerParse')">Formato incorrecto
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm flex-grow-1">
        <label for="numero_factura">Número factura </label>
        <div class="custom-mat-field">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input matInput formControlName="numero_factura" id="numero_factura"
                   placeholder="Número factura" type="text" maxlength="10" (keydown)="onlyNumberKey($event)">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm flex-grow-1">
        <label for="fecha_factura">Fecha factura </label>
        <div class="custom-mat-field">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input matInput [matDatepicker]="datepickerFacturaPoseedor" formControlName="fecha_factura">
            <mat-datepicker-toggle matIconSuffix [for]="datepickerFacturaPoseedor"></mat-datepicker-toggle>
            <mat-datepicker #datepickerFacturaPoseedor>
            </mat-datepicker>
            <mat-error *ngIf="controlHasError('fecha_factura', 'matDatepickerParse')">Formato incorrecto
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row form">
      <div class="col-sm flex-grow-1" *ngIf="canAccessEnvalora()">
        <label for="estado">Estado </label>
        <div class="custom-mat-field-estado">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="estado" id="estado" placeholder="-- Estado --">
              <mat-option *ngFor="let des of estadoArray" [value]="des">
                {{ des.descripcion }}
              </mat-option>
            </mat-select>
            <span *ngIf="showClearButton('estado')" tabindex="0" class="ng-clear-wrapper"
                  (click)="clearSelection($event,'estado')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
          </mat-form-field>
        </div>
      </div>

    </div>

    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" (click)="clearSearcher()">Limpiar</button>
      <button type="button" class="btn btn-primary" (click)="searchData()">Buscar</button>
    </div>
  </form>
  <!-- FIN Formulario de búsqueda -->
</div>
<div class="bg-second-table-info pt-3 pb-5">
  <div *ngIf="flagSearch; else messageClear" class="container">
    <div class="actions-table">
      <div class=""><span class="align-middle tit-table">{{ recordsTotal }} registros encontrados</span></div>
      <div class="text-right" *ngIf="canAccessExport()">
        <div class="d-inline">

          <div class="d-inline dropdown show">
            <a (click)="exportarExcel()" class="btn btn-outline-primary" role="button" aria-haspopup="true"
               aria-expanded="false">
              <span class="mr-3">Exportar</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-tabla-proy">
      <div class="card-body">
        <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
          <div class="datatable-container table-responsive">
            <!-- TABLA Listado con resultados de búsqueda -->
            <lib-ge-table [headArray]="headArray" [gridArray]="gridArray"
                          (actionButton)="action($event)"></lib-ge-table>
            <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
              <div class="d-flex mt-2">
                <ngb-pagination [pageSize]="pageSize" [collectionSize]="recordsTotal" [(page)]="pageNumber"
                                (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination"
                                size="sm">
                </ngb-pagination>
              </div>

              <div class="mt-2 selectPage">
                <label for="itemsPerPage">Mostrar:</label>
                <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                  <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                </select>
              </div>
            </div>
            <!-- FIN TABLA Listado con resultados de búsqueda -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #messageClear>
    <div class="mensaje-container">
      <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
        formulario</p>
      <p class="mensaje-linea"></p>
    </div>
  </ng-template>
</div>
