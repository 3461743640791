import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import moment from 'moment';

import {
  CodigoLerControllerService,
  GestoresResiduosControllerService,
  GestoresResiduosDTO,
  OperacionGestionControllerService,
} from 'api-rest';

@Component({
  selector: 'app-waste-managers-authorized-down-dialog',
  templateUrl: './waste-managers-authorized-down-dialog.component.html',
  styleUrls: ['./waste-managers-authorized-down-dialog.component.scss'],
})
export class WasteManagersAuthorizedDownDialogComponent implements OnInit {
  titulo = 'Solicitud de Baja';
  formGroup!: FormGroup;

  gestorInfo: GestoresResiduosDTO | any;
  objetoResiduo: any;

  constructor(
    public dialogRef: MatDialogRef<WasteManagersAuthorizedDownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private gestoresResiduosControllerService: GestoresResiduosControllerService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService
  ) {
    this.objetoResiduo = this.data.objetoResiduo;
    this.gestorInfo = this.data.gestorInfo;

    this.formGroup = this.formBuilder.group({
      fechaBajaDialog: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
  }

  ngOnInit() {}

  aceptar() {
    this.darBajaResiduo();
  }

  cerrar() {
    this.dialogRef.close(null);
  }

  darBajaResiduo() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }
    const todayDate = new Date();
    const fechaHasta = new Date(this.formGroup.get('fechaBajaDialog')?.value);
    const fechaDesde = new Date(this.objetoResiduo.fechaDesde);
    if(fechaHasta <= fechaDesde){
      this.snackBarSrv.showSnackBar(
        'La fecha de baja debe ser posterior a la fecha desde.',
        'error'
      );
      return;
    }

    let body = this.gestorInfo;
    const objetoEncontrado = body.residuosGestionados.find(
      (objeto: any) => objeto.id === this.objetoResiduo.id
    );
    if (objetoEncontrado) {
      //objetoEncontrado.autorizacionActiva = false;
      objetoEncontrado.fechaHasta = moment(this.formGroup.get('fechaBajaDialog')?.value).format('YYYY-MM-DD');
      objetoEncontrado.autorizacionActiva = todayDate < fechaHasta;
    }
    this.spinnerSrv.loadingShow();
    this.gestoresResiduosControllerService
      .editById4(this.gestorInfo.id!, body)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Los ha dado de baja correctamente.',
            'success'
          );
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }
}
