  <!-- MIGAS DE PAN Y TÍTULO -->
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="bg-second-migas">
    <div class="container mt-cab-fixed">
      <div class="pre-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Poseedores</li>
            <li class="breadcrumb-item">Aportaciones de DIs</li>
          </ol>
        </nav>
        <div class="content-title">
          <h2 class="title-pag">Búsqueda Aportaciones de DIs</h2>

          <div *ngIf="canAccessNuevoDis()" style="display: flex; align-items: center;">
            <div *ngIf="(canViewResource('PO-POSEEDOR') && this.nimaPoseedorArray?.length === 0) ||
              (canViewResource('PO-GESTOR') && this.nimaGestorArray?.length === 0 )" data-placement="top" title="">
              <mat-icon class="show-icon"
                matTooltip="Empiece aportando las cantidades de residuos gestionadas y los DI">warning_amber</mat-icon>
            </div>
            <button *ngIf="canAccessNuevoDis()" type="button" class="btn btn-primary" data-toggle="modal"
              (click)="openModalContributions()"><span class="icon-add"></span>Nueva aportación DI</button>

          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- FIN MIGAS DE PAN Y TÍTULO -->
  <!-- CONTENIDO -->

  <div class="container-form">
    <!-- Formulario de búsqueda -->
    <form class="container form-search py-3" [formGroup]="searchForm">
      <div class="row form">
        <div class="col-sm flex-grow-1" *ngIf="canViewResource('PO-POSEEDOR') || canViewResource('PO-GESTOR')">
          <ng-container>
            <label for="poseedor">Poseedor </label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <mat-select formControlName="poseedor" id="poseedor" placeholder="-- Poseedor --" multiple>
                  <mat-option *ngFor="let poseedor of poseedorArray" [value]="poseedor.id">
                    {{ poseedor.nima }} - {{ poseedor.razonSocial }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <div class="col-sm flex-grow-2" *ngIf="canViewResource('PO-ENV-GEST')||canViewResource('PO-ENV-CONS')">
          <label for="adherido">Poseedor </label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination [endPointFilter]="poseedorEndpoint" [multiple]=true [updateList]="updateList"
              [placeHolder]="'--Poseedores--'" [formControlNameSelect]="getFormControl('poseedor')"
              [descripcion]="getPoseedorDescription" [objQueryName]="getPoseedorObjectQuery"
              [defaultParameters]="defaultPoseedoresParameters">
            </lib-ge-select-pagination>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="canViewResource('PO-POSEEDOR') || canViewResource('PO-GESTOR')">
          <label for="estado">Estado </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="estado" id="estado" placeholder="-- Estado --">
                <mat-option *ngFor="let des of estadoArray" [value]="des">
                  {{ des.descripcionCorta }}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('estado')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'estado')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="canViewResource('PO-ENV-GEST')||canViewResource('PO-ENV-CONS')">
          <label for="idposeedor">ID. Poseedor </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="idposeedor" id="idposeedor" placeholder="ID. Poseedor" type="text" maxlength="10" (keydown)="onlyNumberKey($event)">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="canViewResource('PO-ENV-GEST')||canViewResource('PO-ENV-CONS')">
          <label for="codigo_documento">Documento (NIE, NIF) </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="codigo_documento" id="codigo_documento"
                placeholder="Documento (NIE, NIF)" type="text">
            </mat-form-field>
          </div>
        </div>

      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2" *ngIf="canViewResource('PO-ENV-GEST')||canViewResource('PO-ENV-CONS')">
          <label for="razon_social">Razón Social </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="razon_social" id="razon_social" placeholder="Razón Social" type="text">
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-1">
          <label for="fecha_desde">Fecha recogida desde <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field">
              <input matInput [matDatepicker]="datepicker1" formControlName="fecha_desde">
              <mat-datepicker-toggle matIconSuffix [for]="datepicker1"></mat-datepicker-toggle>
              <mat-datepicker #datepicker1>
              </mat-datepicker>
              <mat-error *ngIf="controlHasError('fecha_desde', 'required')">Campo
                obligatorio</mat-error>
              <mat-error *ngIf="controlHasError('fecha_desde', 'matDatepickerParse')">Formato incorrecto</mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="col-sm flex-grow-1">
          <label for="fecha_hasta">Fecha recogida hasta <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field">
              <input matInput [matDatepicker]="datepicker2" formControlName="fecha_hasta">
              <mat-datepicker-toggle matIconSuffix [for]="datepicker2"></mat-datepicker-toggle>
              <mat-datepicker #datepicker2>
              </mat-datepicker>
              <mat-error *ngIf="controlHasError('fecha_hasta', 'required')">Campo
                obligatorio</mat-error>
              <mat-error *ngIf="controlHasError('fecha_hasta', 'matDatepickerParse')">Formato incorrecto</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="canViewResource('PO-POSEEDOR') || canViewResource('PO-GESTOR')" class="col-sm flex-grow-1">
          <label for="doc_identificacion">Doc. Identificación </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="doc_identificacion" id="doc_identificacion"
                placeholder="Doc. Identificación " type="text">
            </mat-form-field>
          </div>
        </div>

      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1" *ngIf="canViewResource('PO-ENV-GEST')||canViewResource('PO-ENV-CONS')">
          <label for="nima_poseedor">NIMA del poseedor </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="nima_poseedor" id="nima_poseedor" placeholder="NIMA del poseedor"
                type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="canViewResource('PO-ENV-GEST')||canViewResource('PO-ENV-CONS')">
          <label for="nima_gestor">NIMA del gestor </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="nima_gestor" id="nima_gestor" placeholder="NIMA del gestor " type="text">
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="canViewResource('PO-ENV-GEST')||canViewResource('PO-ENV-CONS')" class="col-sm flex-grow-1">
          <label for="doc_identificacion">Doc. Identificación </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="doc_identificacion" id="doc_identificacion"
                placeholder="Doc. Identificación " type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="canViewResource('PO-ENV-GEST')||canViewResource('PO-ENV-CONS')">
          <label for="estado">Estado </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="estado" id="estado" placeholder="-- Estado --">
                <mat-option *ngFor="let des of estadoArray" [value]="des">
                  {{ des.descripcionCorta }}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('estado')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'estado')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="!(canViewResource('PO-ENV-GEST')||canViewResource('PO-ENV-CONS'))"></div>
      </div>

      <div class="button-group">
        <button type="button" class="btn btn-outline-primary" (click)="clearSearcher()">Limpiar</button>
        <button type="button" class="btn btn-primary" (click)="searchData()">Buscar</button>
      </div>
    </form>
    <!-- FIN Formulario de búsqueda -->
  </div>
  <div class="bg-second-table-info pt-3 pb-5">
    <div *ngIf="flagSearch; else messageClear" class="container">
      <div class="actions-table">
        <div class=""><span class="align-middle tit-table">{{ recordsTotal }} registros encontrados</span></div>
        <div class="text-right">
          <div class="d-inline">

            <div class=" d-flex d-inline dropdown show">
              <button *ngIf="canAccessCrearAutoFactura()" type="button" class="btn btn-primary margin-bton-auto"
                data-toggle="modal" (click)="crearAutoFactura()"><span class="icon-add"></span>Crear
                Prefactura</button>
              <a (click)="exportarExcel()" class="btn btn-outline-primary" role="button" aria-haspopup="true"
                aria-expanded="false">
                <span class="mr-3">Exportar</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-tabla-proy">
        <div class="card-body">
          <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
            <div class="datatable-container table-responsive">
              <!-- TABLA Listado con resultados de búsqueda -->
              <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (checked)="checked($event)"
                (actionButton)="action($event)"></lib-ge-table>
              <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
                <div class="d-flex mt-2">
                  <ngb-pagination [pageSize]="pageSize" [collectionSize]="recordsTotal" [(page)]="pageNumber"
                    (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
                  </ngb-pagination>
                </div>

                <div class="mt-2 selectPage">
                  <label for="itemsPerPage">Mostrar:</label>
                  <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                    <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <!-- FIN TABLA Listado con resultados de búsqueda -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #messageClear>
      <div class="mensaje-container">
        <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
          formulario</p>
        <p class="mensaje-linea"></p>
      </div>
    </ng-template>
  </div>
