import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ValidateService } from '../../../services/validate.service';
import { SpinnerService } from '../../../services/spinner.service';
import { Combo, ComboService } from '../../../services/combo.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { PoseedorControllerService, PoseedorDTO } from 'api-rest';
import {
  PoseedorAdheridoPayloadDTO,
  PoseedorCreatePayloadDTO,
} from 'api-rest';
import { MatDialog } from '@angular/material/dialog';
import { HoldersUploadProductionNumComponent } from '../holders-upload-production-num/holders-upload-production-num.component';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lib-holders-detail-general-data',
  templateUrl: './holders-detail-general-data.component.html',
  styleUrls: ['./holders-detail-general-data.component.scss'],
})
export class HoldersDetailGeneralDataComponent implements OnInit, OnChanges {
  @Input() poseedorInfo!: PoseedorDTO;
  @Output() public refrescarPoseedorEvent = new EventEmitter<any>();

  formGroup!: FormGroup;
  iteracionFormGroup!: FormGroup;
  porcentajeFormGroup!: FormGroup;
  cnaeEndpoint = this.environment.urlBackCore + 'api/v1/core/cnae';
  flagDisabledForm: boolean = false;
  updateList: boolean = false;
  mostrarMensajePorc: boolean = false;
  //TODO: ver como cargar los años
  tiposDocumento: Combo[] = [{ id: '2021', name: '2021' }];
  grid: Array<PoseedorAdheridoPayloadDTO> = [];
  anios: any[] = [];
  anioActual!: number;
  flagNumRegTouched: boolean = false;
  anioPrevio!: number;
  nimaAsignadoEnvalora:string = '999999';

  headArray: any[] = [
    {
      Head: 'Año',
      FieldName: 'anio',
    },
    {
      Head: 'Nº registro de productor',
      FieldName: 'registroProductor',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '2',

      permanent: true,
    },
  ];
  constructor(
    private formBuilder: FormBuilder,
    @Inject('environment') private environment: any,
    private comboSrv: ComboService,
    public spinnerSrv: SpinnerService,
    private validateSrv: ValidateService,
    private snackBarSrv: SnackbarService,
    private poseedorControllerSrv: PoseedorControllerService,
    public dialog: MatDialog,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private datePipe: DatePipe
  ) {
    this.formGroup = this.formBuilder.group({
      razonSocial: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      tipoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(15)]) },
      ],
      cnae1: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      cnae2: [{ value: null, disabled: false }],
      //iban: [
      //  { value: null, disabled: false },
      //  [Validators.required, this.formatoValidatorIban()],
      //],
      nima: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.pattern(this.validateSrv.tenDigits),
            Validators.maxLength(10)]
          )
        },
      ],
      denominacionCentro: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      // anioValidezRegistro: [{ value: null, disabled: false }],
      fechaFirmaAcuerdo: [{ value: null, disabled: false }],
      fechaBaja: [{ value: null, disabled: false }],
      fechaBajaEnvanet: [{ value: null, disabled: false }],
      autorizacionComuniaciones: [
        { value: true, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      contratoGestor: [
        { value: true, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      acuerdoGestorEnvalora: [
        { value: true, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });

    this.iteracionFormGroup = this.formBuilder.group({
      anio: [
        { value: null, disabled: false },
      ],
      numRegistroProductor: [
        { value: null, disabled: false },
        [this.formatoValidator()],
      ],
    });

    this.porcentajeFormGroup = this.formBuilder.group({
      porcAdheridos: [{ value: null, disabled: false }],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.poseedorInfo) {
      this.inicializar();
    }
  }
  inicializar() {
    this.iteracionFormGroup.get('anio')?.setValue(new Date().getFullYear());
    this.cargarDatos(this.poseedorInfo);
    this.controlGeneralForm();
  }

  controlGeneralForm() {
    if (this.canAccessUpdate()) {
      this.formGroup.enable();
      if(!this.canEditAgreement()){
        this.formGroup.get('contratoGestor')?.disable();
        this.formGroup.get('acuerdoGestorEnvalora')?.disable();
      }
    } else {
      this.formGroup.disable();
    }
  }

  canAccessUpdate(): boolean {
    
    return this.authService.can('poseedores-detalle', 'access-update') && this.canEditGeneralData();
  }
  canUpdateBaja(): boolean {
    return this.authService.can('poseedores-detalle', 'udpate-baja') && this.canEditGeneralData();
  }

  ngOnInit(): void {
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    this.anioActual = new Date().getFullYear();
    for (let i = this.anioActual - 3; i <= this.anioActual + 1; i++) {
      this.anios.push({ id: i });
    }
    this.iteracionFormGroup.get('anio')?.setValue(this.anioActual);
  }

  cargarDatos(poseedor: PoseedorDTO): void {
    this.formGroup.get('razonSocial')?.setValue(poseedor?.razonSocial);
    this.formGroup.get('tipoDocumento')?.setValue(poseedor?.tipoDocumento);
    this.formGroup.get('codigoDocumento')?.setValue(poseedor?.codigoDocumento);
    this.formGroup.get('cnae1')?.setValue(poseedor?.cnae1?.descripcion);
    this.formGroup.get('cnae2')?.setValue(poseedor?.cnae2?.descripcion);
    this.addSpaceFormatIban(poseedor?.iban, true);
    this.formGroup
      .get('denominacionCentro')
      ?.setValue(poseedor?.denominacionCentro);
    this.formGroup.get('nima')?.setValue(poseedor?.nima);
    if (poseedor?.nima === '99999999') {
      this.snackBarSrv.showSnackBar(
        'Recuerda actualizar la información de su identificador medioambiental NIMA',
        'error'
      );
    }
    // let fechaFirmaContrato =
    //   poseedor?.fechaFirmaContrato != null
    //     ? moment(new Date(poseedor?.fechaFirmaContrato!)).format('DD/MM/YYYY')
    //     : null;
    let fechaFirmaContrato =
      poseedor?.fechaFirmaContrato != null
        ? this.datePipe.transform(poseedor?.fechaFirmaContrato, 'dd/MM/yyyy')
        : null;
    this.formGroup.get('fechaFirmaAcuerdo')?.setValue(fechaFirmaContrato);
    this.formGroup.get('fechaBaja')?.setValue(poseedor?.fechaBaja);
    let fechaBajaEvanet =
      poseedor?.fechaBajaEvanet != null
        ? this.datePipe.transform(poseedor?.fechaBajaEvanet, 'dd/MM/yyyy')
        : null;
    this.formGroup.get('fechaBajaEnvanet')?.setValue(fechaBajaEvanet);
    this.formGroup
      .get('autorizacionComuniaciones')
      ?.setValue(!!poseedor?.autorizacionComuniaciones);
    this.tipoDocumentoSel(this.formGroup
      .get('tipoDocumento')?.value);
    // si el estado es igual o superior a 2 – Pdte generar acuerdo, los campos tipo documento y documento en modo lectura
    if (poseedor?.estado?.id && poseedor?.estado?.id >= 2) {
      this.formGroup.get('tipoDocumento')?.disable();
      this.formGroup.get('codigoDocumento')?.disable();
    }
    this.anioSelected();
    this.formGroup.get('contratoGestor')?.setValue(!!poseedor?.contratoGestor);
    this.formGroup.get('acuerdoGestorEnvalora')?.setValue(!!poseedor?.acuerdoGestorEnvalora);
  }
  canAccessAll(list: any) {
    const resultDelete = this.canAccessUpdate();

    const listPermission = list.map((objeto: any) => {
      return {
        ...objeto,
        canAccessDelete: resultDelete,
      };
    });

    return listPermission;
  }

  onMatSelectOpen(form: any): void {
    this.anioPrevio = form.get('anio')?.value
  }

  anioSelected(): void {
    const anioPrevio = this.anioPrevio;
    if (this.flagNumRegTouched) {
      Swal.fire({
        text: 'Esá cambiando de año, si lo hace sin guardar previamente, se perderán los cambios para el año seleccionado. ¿Desea continuar?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
        .then((result: any) => {
          if (result.isConfirmed) {
            this.seleccionoAnio();
          } else {
            this.iteracionFormGroup.get('anio')?.setValue(anioPrevio);
          }
        });
    } else {
      this.seleccionoAnio();
    }
  }

  seleccionoAnio() {
    this.flagNumRegTouched = false;
    if (
      this.poseedorInfo?.registroProductores &&
      this.poseedorInfo?.registroProductores?.length > 0
    ) {
      let numerosReg = this.poseedorInfo?.registroProductores.filter(
        (x) => x.anio === this.iteracionFormGroup.get('anio')?.value
      );
      numerosReg.forEach((el) => {
        el.registroProductor = el.registroProductor?.replace(/\//g, '');
      });
      numerosReg.forEach((el) => {
        el.registroProductor = this.ponerBarras(el.registroProductor!);
      });
      this.grid = this.canAccessAll(numerosReg);
      this.cargarPorcentajeAdheridos();
    }
  }
  ponerBarras(numReg: string): string {
    return (
      numReg.substring(0, 3) +
      '/' +
      numReg.substring(3, 7) +
      '/' +
      numReg.substring(7)
    );
  }
  getCnaDescription(cnae: any) {
    return cnae?.clase + ' - ' + cnae?.descripcion;
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'cnae1': {
        return this.formGroup.get('cnae1') as FormControl;
      }
      case 'cnae2': {
        return this.formGroup.get('cnae2') as FormControl;
      }
      default: {
        return this.formGroup.get('') as FormControl;
      }
    }
  }
  getCnaeObjectQuery(searchterm: string) {
    return {
      descripcion: `or(ci(${searchterm}))`,
      clase: `or(ci(${searchterm}))`,
    };
  }
  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  tipoDocumentoSel(idTipoDoc: string) {
    this.formGroup.get('codigoDocumento')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      this.formGroup
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.nifPattern),
        ]);
    } else if (idTipoDoc === 'DNI') {
      this.formGroup
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.dniPattern),
        ]);
    } else if (idTipoDoc === 'NIE') {
      this.formGroup
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.niePattern),
        ]);
    }
    this.formGroup.get('codigoDocumento')?.updateValueAndValidity();
    this.formGroup.get('codigoDocumento')?.markAsTouched();
  }
  controlHasError(
    formGroup: FormGroup,
    controlName: string,
    errorName: string
  ) {
    return (
      formGroup.controls[controlName].hasError(errorName) &&
      formGroup.controls[controlName].touched
    );
  }

  addNumRegistro(): void {
    if (this.iteracionFormGroup.invalid) {
      this.iteracionFormGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Revisar el campo Nº registro de productor ',
        'error'
      );
      return;
    }

    const existe = this.grid.find(
      (x) =>
        x.anio === this.iteracionFormGroup.get('anio')?.value &&
        x.registroProductor ===
        this.iteracionFormGroup.get('numRegistroProductor')?.value
    );
    if (existe) {
      this.snackBarSrv.showSnackBar(
        'Ya existe el número de registro de producción introducido',
        'error'
      );
    } else {
      const obj = {
        anio: this.iteracionFormGroup.get('anio')?.value,
        registroProductor: this.iteracionFormGroup.get('numRegistroProductor')
          ?.value,
      };
      this.grid.push(obj);
      this.grid = this.canAccessAll([...this.grid]);
      this.iteracionFormGroup.get('numRegistroProductor')?.setValue(null);
      this.iteracionFormGroup.markAsPristine();
      this.iteracionFormGroup.markAsUntouched();
      this.cargarPorcentajeAdheridos();
      this.flagNumRegTouched = true;
    }
  }

  cargarPorcentajeAdheridos(): void {
    this.mostrarMensajePorc = false;
    let array: any = [];
    this.grid.forEach((el) => {
      array.push(el.registroProductor?.replace(/\//g, ''));
    });
    // let array = this.grid.map((x) => x.registroProductor!.replace(/\//g, ''));
    if (array.length > 0) {
      this.spinnerSrv.loadingShow();
      this.poseedorControllerSrv.obtenerPorcentajeAdheridos(array).subscribe({
        next: (response: number) => {
          this.spinnerSrv.loadingHide();
          this.porcentajeFormGroup.get('porcAdheridos')?.setValue(response);
          if (response === 0) {
            this.mostrarMensajePorc = true;
          }
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          console.log(err);
        },
      });
    } else {
      this.porcentajeFormGroup.get('porcAdheridos')?.setValue(null);
    }
  }

  action(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarNumRegistro(event);
    }
  }

  confirmacionEliminarNumRegistro(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el número de registro del productor?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.snackBarSrv.showSnackBar(
          `Se ha eliminando correctamente.`,
          'success'
        );

        const index = this.grid.indexOf(element);
        if (index > -1) {
          this.grid.splice(index, 1);
        }
        const i = this.poseedorInfo?.registroProductores?.indexOf(element);
        if (
          i !== undefined &&
          i > -1 &&
          this.poseedorInfo?.registroProductores &&
          this.poseedorInfo?.registroProductores?.length > 0
        ) {
          this.poseedorInfo.registroProductores.splice(i, 1);
        }
        if (this.grid.length > 0) {
          this.cargarPorcentajeAdheridos();
        } else {
          this.mostrarMensajePorc = false;
          this.porcentajeFormGroup.get('porcAdheridos')?.setValue('');
        }
        this.flagNumRegTouched = true;
      }
    });
  }
  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (!this.formGroup.valid) {
      this.snackBarSrv.showSnackBar('Revise los campos', 'error');
    }

    if (this.formGroup.valid) {
      let body: PoseedorCreatePayloadDTO = this.poseedorInfo;
      body.razonSocial = this.formGroup.get('razonSocial')?.value;
      body.tipoDocumento = this.formGroup.get('tipoDocumento')?.value;
      body.codigoDocumento = this.formGroup.get('codigoDocumento')?.value;
      body.iban = this.formGroup.get('iban')?.value?.replace(/\s/g, ''); // Enviamos el iban sin formato
      body.denominacionCentro = this.formGroup.get('denominacionCentro')?.value;
      body.nima = this.formGroup.get('nima')?.value;
      body.fechaBaja = (this.formGroup.get('fechaBaja')?.value) ? moment(new Date(this.formGroup.get('fechaBaja')?.value)).format('YYYY-MM-DD') : '';
      body.autorizacionComuniaciones = this.formGroup.get(
        'autorizacionComuniaciones'
      )?.value;

      // Si es un string es porque no he modificado el valor que me ha llegado del servicio
      let cnaePrincipal = this.formGroup.get('cnae1')?.value;
      if (typeof cnaePrincipal === 'string') {
        body.cnae1 = this.poseedorInfo?.cnae1;
      } else {
        body.cnae1 = this.formGroup.get('cnae1')?.value;
      }
      let cnaeSecundario = this.formGroup.get('cnae2')?.value;
      if (typeof cnaeSecundario === 'string') {
        body.cnae2 = this.poseedorInfo?.cnae2;
      } else {
        body.cnae2 = this.formGroup.get('cnae2')?.value;
      }

      //Recupero los que son diferentes al año seleccionado
      body.registroProductores = this.poseedorInfo?.registroProductores?.filter(
        (x) => x.anio !== this.iteracionFormGroup.get('anio')?.value
      );
      //Los del año seleccionado, los cojo de la tabla
      this.grid.forEach((el) => {
        if (body.registroProductores) {
          body.registroProductores.push({
            anio: el.anio,
            registroProductor: el.registroProductor?.replace(/\//g, ''),
          });
        }
      });

      if (this.canEditAgreement()) {
        body.contratoGestor = this.formGroup.get('contratoGestor')?.value;
        body.acuerdoGestorEnvalora = this.formGroup.get('acuerdoGestorEnvalora')?.value;
      }

      this.spinnerSrv.loadingShow();
      this.poseedorControllerSrv
        .editById3(this.poseedorInfo.id!, body)
        .subscribe({
          next: (response: PoseedorDTO) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Los datos generales del Poseedor se han guardado correctamente',
              'success'
            );
            this.flagNumRegTouched = false;
            this.refrescarPoseedorEvent.emit(true);
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar los datos generales',
              'error'
            );
          },
        });
    }
  }

  uploadProductionNum() {
    const dialogRef = this.dialog.open(HoldersUploadProductionNumComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        poseedorInfo: this.poseedorInfo,
        anio: this.iteracionFormGroup.get('anio')?.value,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.length > 0) {
        result.forEach((el: string) => {
          let existe = this.grid.find((x) => x.registroProductor === el);
          if (!existe) {
            this.grid.push({
              anio: this.iteracionFormGroup.get('anio')?.value,
              registroProductor: el,
            });
            this.snackBarSrv.showSnackBar(
              'Importación realizada correctamente',
              'success'
            );
            this.grid = this.canAccessAll([...this.grid]);
          } else {
            this.snackBarSrv.showSnackBar(
              'Ya existe número de registro de producción introducido',
              'error'
            );
          }
        });
        this.cargarPorcentajeAdheridos();
      }
    });
  }

  agregarBarraAutomaticamente(event?: any, carga: boolean = false) {
    let valor = carga ? event : event.target.value;
    if (valor != null) {
      valor = valor.replace(/[^A-Za-z0-9]/g, '');

      if (valor.length >= 3 && valor.length < 7) {
        valor = valor.substring(0, 3) + '/' + valor.substring(3);
      } else if (valor.length >= 7) {
        valor =
          valor.substring(0, 3) +
          '/' +
          valor.substring(3, 7) +
          '/' +
          valor.substring(7);
      }
    }
    this.iteracionFormGroup.get('numRegistroProductor')?.setValue(valor);
  }
  formatoValidator() {
    return (control: FormControl) => {
      const valor = control.value;
      if (!valor) {
        return null;
      }
      const regex = /^ENV\/\d{4}\/\d{9}$/;
      if (!regex.test(valor)) {
        return { formatoInvalido: true };
      }

      if (valor.length !== 18) {
        return { longitudIncorrecta: true };
      }

      return null;
    };
  }

  formatoValidatorIban() {
    return (control: FormControl) => {
      const valor = control.value;
      if (!valor) {
        return null;
      }
      // Verificar que esté correctamente formateado con espacios cada 4 caracteres
      const regex = /^ES\d{2}(?:\s?\d{4}){5}$/;
      if (!regex.test(valor)) {
        return { invalidIbanFormat: true };
      }

      // Eliminar espacios del valor
      const valorSinEspacios = valor.replace(/\s/g, '');

      if (!valorSinEspacios.startsWith('ES')) {
        return { invalidIban: true };
      }

      // Verificar que tenga exactamente 24 caracteres (excluyendo espacios)
      if (valorSinEspacios.length !== 24) {
        return { invalidLength: true };
      }

      return null;
    };
  }

  addSpaceFormatIban(event?: any, carga: boolean = false) {
    let valor = carga ? event : event.target.value;

    if (valor != null) {
      valor = valor.replace(/[^A-Za-z0-9]/g, '');

      // Asegurar que las dos primeras letras sean ES
      if (valor.length >= 2) {
        const prefix = valor.substring(0, 2).toUpperCase();
        if (prefix !== 'ES') {
          valor = 'ES' + valor.substring(2);
        }
      }

      // Asegurar que solo haya letras en las dos primeras posiciones y números en el resto
      if (valor.length > 2) {
        // Los primeros dos caracteres después de ES deben ser números
        const digitsAfterES = valor.substring(2, 4).replace(/[^0-9]/g, '');
        valor = 'ES' + digitsAfterES + valor.substring(4).replace(/[^0-9]/g, '');
      }

      // Insertar un espacio después de los dos primeros números y luego cada 4 caracteres
      if (valor.length > 4) {
        const firstPart = valor.substring(0, 4); // "ESXX"
        const rest = valor.substring(4).match(/.{1,4}/g)?.join(' ') || '';
        valor = firstPart + (rest ? ' ' + rest : '');
      }

      // Limitar a 24 caracteres en total
      if (valor.length > 29) {
        valor = valor.substring(0, 29);
      }
    }
    // Actualizar el valor del formulario
    this.formGroup.get('iban')?.setValue(valor);
  }
  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  agregarPrefijoSiEstaVacio(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.value) {
      inputElement.value = 'ENV/';
      this.formGroup.get("numRegistroProductor")?.setValue(inputElement.value);
    }
  }

  canEditAgreement(): boolean {
    return this.canViewResource('PO-ENV-GEST') || (this.canViewResource('PO-POSEEDOR') && this.poseedorInfo?.estado?.id === 1);
  }

  canViewAgreement(): boolean {
    //return this.authService.can('poseedores-detalle', 'access-acuerdo');
    return this.canViewResource('PO-ENV-GEST') || this.canViewResource('PO-POSEEDOR') || this.canViewResource('PO-ENV-CONS');
  }

  canEditGeneralData(): boolean {
    return this.canViewResource('PO-ENV-GEST') || (this.canViewResource('PO-POSEEDOR') && this.poseedorInfo?.estado?.id === 1);
  }

}
