/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProvinciaDTO } from './provinciaDTO';
import { ComunidadDTO } from './comunidadDTO';
import { GestoresResiduosEstadosDTO } from './gestoresResiduosEstadosDTO';


export interface GestionResiduosFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    id?: Array<number>;
    codigoDocumento?: string;
    razonSocial?: string;
    provincia?: ProvinciaDTO;
    comunidadAutonoma?: ComunidadDTO;
    nima?: Array<string>;
    autorizacionPeligrosos?: boolean;
    numInscripcion1?: string;
    gestoresResiduosEstado?: GestoresResiduosEstadosDTO;
    acuerdoEnvalora?: boolean;
    gestorResiduos?: boolean;
    acondicionadorEnvase?: boolean;
    abonoDeposito?: GestionResiduosFilterDTO.AbonoDepositoEnum;
    completados?: boolean;
    byUser?: boolean;
    descripcionTipoEnvase?: string;
}
export namespace GestionResiduosFilterDTO {
    export type AbonoDepositoEnum = 'ACONDICIONADOR_ENVASES' | 'ENVALORA';
    export const AbonoDepositoEnum = {
        AcondicionadorEnvases: 'ACONDICIONADOR_ENVASES' as AbonoDepositoEnum,
        Envalora: 'ENVALORA' as AbonoDepositoEnum
    };
}


