import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumControllerService, PoseedorDTO, TipoDocumentoControllerService, TipoDocumentoModel } from 'api-rest';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'lib-holders-new-document-dialog',
  templateUrl: './holders-new-document-dialog.component.html',
  styleUrls: ['./holders-new-document-dialog.component.scss']
})
export class HoldersNewDocumentDialogComponent {

  formGroup!: FormGroup;
  poseedor!: PoseedorDTO;
  tiposDocumento: any[] = [];
  adjuntosBlob: Array<any> = [];
  botonEstado: boolean = false;
  flagTooltip: boolean = false;

  containerPoseedores: string = this.environment.documentContainerHolders;

  constructor(
    @Inject('environment') private environment: any,
    public dialogRef: MatDialogRef<HoldersNewDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private documControllerSrv: DocumControllerService,
    private tipoDocumentoControllerSrv: TipoDocumentoControllerService
  ) {
    this.poseedor = this.data.poseedor;

    this.formGroup = this.formBuilder.group({
      tipoDocumento: [{ value: null, disabled: false }, { validators: Validators.compose([Validators.required]) }],
      descripcion: [{ value: null, disabled: false }, Validators.maxLength(255)]
    });
  }

  ngOnInit(): void {
    this.cargarDocumentos();
    this.cargarTooltip();
  }

  cargarTooltip() {
    this.formGroup.get('tipoDocumento')?.valueChanges.subscribe((value: any) => {
      if (value == 4) {
        this.flagTooltip = true;
      } else {
        this.flagTooltip = false;
      }
    });
  }

  cargarDocumentos(): void {
    this.spinnerSrv.loadingShow();
    this.tipoDocumentoControllerSrv.listarTipoDocumentos()
      .subscribe({
        next: (response: TipoDocumentoModel[]) => {
          this.spinnerSrv.loadingHide();
          // Filtrar solo los documentos con ids 1, 4 y 5 (contrato, otros, adenda)
          this.tiposDocumento = response.filter((doc: TipoDocumentoModel) => [1, 4, 5].includes(Number(doc.id)));
        },
        error: err => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar('Error al cargar los tipos de documentos', 'error');
        }
      });
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  guardar(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {
      if (this.adjuntosBlob?.length > 0) {
        let id = this.poseedor.id! ?? null;
        let typeDoc = this.formGroup.controls['tipoDocumento'].value! ?? null;
        let docDesc = this.formGroup.controls['descripcion'].value! ?? '';
        this.setBotonEstado(true);
        this.documControllerSrv.uploadDoc(this.containerPoseedores, typeDoc, id, 'poseedor', this.adjuntosBlob[0]?.name, this.adjuntosBlob[0], docDesc )
          .subscribe({
            next: () => {
              this.snackBarSrv.showSnackBar('Documento subido correctament', 'success');
              this.dialogRef.close(true);
            },
            error: err => {
              this.setBotonEstado(false);
              this.snackBarSrv.showSnackBar('Error al subir documento', 'error');
            }, complete: () => {
              this.spinnerSrv.loadingHide();
            },
          });
      }
    }
  }
  controlHasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName)
      && this.formGroup.controls[controlName].touched;
  }

  attachAdjunto(archivo: File | null): void {
    if (archivo != null) {
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.adjuntosBlob.push(archivo);
      }
    }
  }

  setBotonEstado(estado: boolean) {
    return this.botonEstado = estado;
  }
}
