/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ResiduosGestionadosModel } from './residuosGestionadosModel';
import { GestoresResiduosEstadosModel } from './gestoresResiduosEstadosModel';
import { AcondicionamientoEnvaseModel } from './acondicionamientoEnvaseModel';
import { ContactoModel } from './contactoModel';
import { DireccionModel } from './direccionModel';


export interface GestoresResiduosModel { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    deleted?: string;
    deletedBy?: string;
    id?: number;
    razonSocial?: string;
    tipoDocumento?: GestoresResiduosModel.TipoDocumentoEnum;
    codigoDocumento?: string;
    nima?: string;
    gestoresResiduosEstado?: GestoresResiduosEstadosModel;
    fechaAltaFinalizada?: string;
    fechaBaja?: string;
    fechaBajaEnvanet?: string;
    numeroAutorizacion?: string;
    centro?: string;
    numInscripcion1?: string;
    numInscripcion2?: string;
    numInscripcion3?: string;
    acuerdoEnvalora?: boolean;
    gestorResiduos?: boolean;
    acondicionadorEnvase?: boolean;
    abonoDeposito?: GestoresResiduosModel.AbonoDepositoEnum;
    contactos?: Array<ContactoModel>;
    domicilios?: Array<DireccionModel>;
    residuosGestionados?: Array<ResiduosGestionadosModel>;
    acondicionamientoEnvase?: Array<AcondicionamientoEnvaseModel>;
}
export namespace GestoresResiduosModel {
    export type TipoDocumentoEnum = 'NIF' | 'DNI' | 'NIE';
    export const TipoDocumentoEnum = {
        Nif: 'NIF' as TipoDocumentoEnum,
        Dni: 'DNI' as TipoDocumentoEnum,
        Nie: 'NIE' as TipoDocumentoEnum
    };
    export type AbonoDepositoEnum = 'ACONDICIONADOR_ENVASES' | 'ENVALORA';
    export const AbonoDepositoEnum = {
        AcondicionadorEnvases: 'ACONDICIONADOR_ENVASES' as AbonoDepositoEnum,
        Envalora: 'ENVALORA' as AbonoDepositoEnum
    };
}


