<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Gestor</li>
          <li *ngIf="flagRuta" class="breadcrumb-item"><a href="#/holders/waste-managers-search">Búsqueda de
              Gestores</a>
          </li>
          <li class="breadcrumb-item active">Datos de Gestor</li>
        </ol>
      </nav>
      <div class="d-flex content-title">
        <div class="d-flex">
          <h2 class="title-pag">Datos de Gestor</h2>
          <div *ngIf="datosCargados" class="custom-container" [ngStyle]="{'background-color': (gestorInicial ? '#fff3af' : gestorBaja ? '#ffffff' :
                gestorCompletado ?
                '#a4ffb0f5' :'#ffffff')}">
            <div>
              <label style="font-size: larger; padding-top: 5px;">
                <mat-icon *ngIf="textoEstado!=''" class="show-icon" [ngStyle]="{'color': (gestorInicial ? '#eb1d1d' : gestorBaja ? 'initial' :
                gestorCompletado ?
                'darkgreen' :'initial')}">{{ gestorCompletado ? 'check_circle' : 'warning_amber'}}</mat-icon>
              </label>
            </div>
            <div>
              <label class="info-texto-estado" [ngStyle]="{'color': (gestorInicial ? '#eb1d1d' : gestorBaja ? 'initial' :
                gestorCompletado ?
                'darkgreen' :'initial')}">
                {{textoEstado}}</label>
            </div>
            <div *ngIf="this.gestorInfo?.gestoresResiduosEstado?.infoAdicional" style="padding-top: 8px;">
              <div class="tool-help" matTooltip={{this.gestorInfo?.gestoresResiduosEstado?.infoAdicional}}>
                <span>i</span>
              </div>
            </div>
          </div>
        </div>
        <div class="nota mt-2">Los campos señalados con asterisco <span class="oblig">*</span> son obligatorios
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->

<div class="container">
  <div class="card-ficha">
    <div class="card-body">
      <div class="ficha-2col">
        <div class="col-tab-list">
          <ul class="list">
            <li [ngClass]=" {'active':(selectedMenu===1)}"
              (click)="!canAccessContinuarAll(1)?loadNumMenu(1):mensajeValidacion()">
              1. Datos generales
            </li>
            <li [ngClass]="{'active':(selectedMenu===2)}"
              (click)="!canAccessContinuarAll(1)?loadNumMenu(2):mensajeValidacion()">
              2. Datos de contacto
            </li>
            <li [ngClass]="{'active':(selectedMenu===3)}"
              (click)="!canAccessContinuarAll(1)?loadNumMenu(3):mensajeValidacion()">
              3. Direcciones
            </li>
            <li [ngClass]="{'active':(selectedMenu===4)}" *ngIf="!(gestorInfo?.acondicionadorEnvase == true && gestorInfo?.gestorResiduos == false)" 
              (click)="!canAccessContinuarAll(1)?loadNumMenu(4):mensajeValidacion()">
              4. Residuos autorizados
            </li>
            <li [ngClass]="{'active':(selectedMenu===5)}" *ngIf="gestorInfo?.acondicionadorEnvase == true && gestorInfo?.gestorResiduos == false" 
              (click)="!canAccessContinuarAll(1)?loadNumMenu(5):mensajeValidacion()">
              4. Acondicionamiento de envases
            </li>
            <li [ngClass]="{'active':(selectedMenu===5)}" *ngIf="gestorInfo?.acondicionadorEnvase == true && gestorInfo?.gestorResiduos == true" 
              (click)="!canAccessContinuarAll(1)?loadNumMenu(5):mensajeValidacion()">
              5. Acondicionamiento de envases
            </li>
            <li *ngIf="canAccessDocumentos() && gestorEstado!>=2 && gestorInfo?.acondicionadorEnvase == true && gestorInfo?.gestorResiduos == true" [ngClass]="{'active':(selectedMenu===6)}"
              (click)="loadNumMenu(6)">
              6. Documentos
            </li>
            <li *ngIf="canAccessDocumentos() && gestorEstado!>=2 && (gestorInfo?.acondicionadorEnvase == false || gestorInfo?.gestorResiduos == false)" [ngClass]="{'active':(selectedMenu===6)}"
              (click)="loadNumMenu(6)">
              5. Documentos
            </li>
          </ul>
        </div>
        <div *ngIf="selectedMenu===1" class="col-content">
          <lib-waste-managers-detail-general-data [gestorInfo]="gestorInfo" (refrescarGestorEvent)="refrescar(1)">
          </lib-waste-managers-detail-general-data>
        </div>
        <div *ngIf="selectedMenu===2" class="col-content">
          <lib-waste-managers-detail-contact-data [gestorInfo]="gestorInfo" (refrescarGestorEvent)="refrescar(2)">
          </lib-waste-managers-detail-contact-data>
        </div>
        <div *ngIf="selectedMenu===3" class="col-content">
          <lib-waste-managers-detail-address [gestorInfo]="gestorInfo" (refrescarGestorEvent)="refrescar(3)">
          </lib-waste-managers-detail-address>
        </div>
        <div *ngIf="selectedMenu===4" class="col-content">
          <lib-waste-managers-detail-authorized-waste [gestorInfo]="gestorInfo" (refrescarGestorEvent)="refrescar(4)">
          </lib-waste-managers-detail-authorized-waste>
        </div>
        <div *ngIf="selectedMenu===5" class="col-content">
          <lib-waste-managers-detail-package-reconditioning [gestorInfo]="gestorInfo" (refrescarGestorEvent)="refrescar(5)">
          </lib-waste-managers-detail-package-reconditioning>
        </div>
        <div *ngIf="selectedMenu===6 && canAccessDocumentos() && gestorEstado!>=2" class="col-content">
          <lib-waste-managers-detail-document [gestorInfo]="gestorInfo" (refrescarGestorEvent)="refrescar(6)">
          </lib-waste-managers-detail-document>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="button-actions">
      <button type="button" *ngIf="canAccessCancelar(selectedMenu)" (click)="buttonCancelar()"
        class="btn btn-outline-primary">
        <div class="text-icon">Cancelar</div>
      </button>
      <button type="button" *ngIf="canAccessVolver()" (click)="buttonVolver()" class="btn btn-outline-primary">
        <div class="text-icon">Volver</div>
      </button>
      <button type="button" *ngIf="canAccessAnteriorAll(selectedMenu) && selectedMenu!==1 "
        class="btn btn-outline-primary" (click)="anterior()">
        <div class="text-icon"><span class="icon-arrow-left"></span>Anterior</div>
      </button>
      <button type="button" *ngIf="canAccessContinuarAll(selectedMenu) || canAccessGuardar(selectedMenu)"
        class="btn btn-outline-primary" (click)="controlAccionsWizard(selectedMenu)">
        <div class="text-icon">{{continuarName(selectedMenu)}}<span *ngIf="continuarName(selectedMenu)!='Guardar'"
            class="icon-arrow-right"></span></div>
      </button>
      <button type="button" *ngIf="canAccessFinalizar(selectedMenu) && selectedMenu===4" class="btn btn-outline-primary"
        (click)="controlAccionsWizard(selectedMenu)">
        <div class="text-icon">Finalizar</div>
      </button>
    </div>
  </div>
