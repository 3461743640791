import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CodigoLerControllerService,
  CodigoOperacionControllerService,
  CodigoOperacionFilterDTO,
  GestoresResiduosControllerService,
  GestoresResiduosDTO,
  OperacionGestionControllerService,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { MatDialog } from '@angular/material/dialog';
import { WasteManagersAuthorizedDownDialogComponent } from '../waste-managers-authorized-down-dialog/waste-managers-authorized-down-dialog.component';
import moment from 'moment';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';

@Component({
  selector: 'lib-waste-managers-detail-authorized-waste',
  templateUrl: './waste-managers-detail-authorized-waste.component.html',
  styleUrls: ['./waste-managers-detail-authorized-waste.component.scss'],
})
export class WasteManagersDetailAuthorizedWasteComponent implements OnInit {
  @Input() gestorInfo!: GestoresResiduosDTO | any;
  @Output() public refrescarGestorEvent = new EventEmitter<any>();

  formGroup!: FormGroup;
  codigosLerEndpoint =
    this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?poseedores=true';

  grid: any[] = [];
  operacionArray: any[] = [];

  pageNumber: number = 0;
  pageSize: number = 100;
  recordsTotal: number = 0;
  gestionArray: any[] = [];

  updateList: boolean = false;

  headArray: any[] = [
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'descripcionMCodLer',
      Tooltip: true,
    },
    {
      Head: 'Operación de gestión específica ',
      FieldName: 'operacionMGestion',
      Tooltip: true,
    },
    {
      Head: 'Cantidad anual autorizada (t)',
      FieldName: 'cantidadAnualAutorizada',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item?.cantidadAnualAutorizada),
    },
    {
      Head: 'Fecha desde',
      FieldName: 'fechaMDesde',
    },
    {
      Head: 'Fecha hasta',
      FieldName: 'fechaMHasta',
      icon: 'info',
      tooltip: 'Indicar la fecha solo cuando el gestor finalice el contrato de gestión para ese residuo, a través del botón "Dar baja"',
    },
    {
      Head: 'Autorización activa',
      FieldName: 'autorizacionActiva',
      renderValue: (item: any) => {
        const todayDate = new Date();
        const fechaDesde = new Date(item?.fechaDesde);
        const fechaHasta = item?.fechaHasta ? new Date(item.fechaHasta) : null;
        return todayDate > fechaDesde && (!fechaHasta || todayDate < fechaHasta) ? 'SI' : 'NO';
      },
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'dar_baja',
          icon: 'move_to_inbox',
          toolTip: 'Dar Baja',
          show: { params: 'canAccessDown', accion: 'dar_baja' },
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private codigoLerControllerSrv: CodigoLerControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private operacionGestionControllerService: OperacionGestionControllerService,
    private gestoresResiduosControllerService: GestoresResiduosControllerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private codigoOperacionControllerService: CodigoOperacionControllerService,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.formGroup = this.formBuilder.group({
      codigoLer: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      operacionGestion: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      cantidadAnual: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      fechaDesde: [
        {value: null, disabled: false},
        {validators: Validators.compose([Validators.required])},
      ],
    });
  }

  ngOnInit() {
    this.getComboOperacionGestion();
    this.valueChangeCodigoLer();
  }

  valueChangeCodigoLer() {
    this.formGroup.get('codigoLer')?.valueChanges.subscribe((value: any) => {
      if (value) {
        this.buscarOperacionesGestion();
      } else {
        this.gestionArray = [];
      }
    });
  }

  buscarOperacionesGestion() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.operacionGestionControllerService
      .findByCodigoOperacion(backendFilter, { page: page, size: size })
      .subscribe({
        next: (res) => {
          if (res) {
            this.gestionArray = res?.datos ?? [];
          }
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  private getBackendFilter() {
    const backendFilter: CodigoOperacionFilterDTO = {
      codigoLer: [this.formGroup.get('codigoLer')?.value?.id],
      poseedores: true
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gestorInfo) {
      this.grid = this.canAccessAll(this.gestorInfo?.residuosGestionados!);
    }
  }

  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }

  getOperacionDescription(op: any) {
    return op?.descripcion;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.formGroup.get('codigoLer') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }

  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  addPrevision() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }
    const dataObjtable = {
      id: Math.random(),
      codigoLer: this.formGroup.get('codigoLer')?.value,
      operacionGestion: this.formGroup.get('operacionGestion')?.value,
      cantidadAnualAutorizada: this.parseCurrencyPipe.transform(this.formGroup.get('cantidadAnual')?.value),
      fechaDesde: moment(this.formGroup.get('fechaDesde')?.value).format('YYYY-MM-DD'),
      fechaMDesde: moment(
        new Date(this.formGroup.get('fechaDesde')?.value)
      ).format('DD/MM/YYYY'),
      gestoresResiduos: { id: this.gestorInfo?.id },
      canAccessDelete:
        this.gestorInfo.gestoresResiduosEstado?.id == 1 &&
        this.authService.hasCurrentUserRole('PO-GESTOR')
          ? true
          : false,
      autorizacionActiva: true,
      descripcionMCodLer:
        this.formGroup.get('codigoLer')?.value?.tipoCodigo +
        ' ' +
        this.formGroup.get('codigoLer')?.value?.descripcion,
      operacionMGestion:
        this.formGroup.get('operacionGestion')?.value?.codigo +
        ' ' +
        this.formGroup.get('operacionGestion')?.value?.descripcion,
    };
    if (
      !(
        this.gestorInfo.gestoresResiduosEstado?.id == 1 &&
        this.authService.hasCurrentUserRole('PO-GESTOR')
      )
    ) {
      this.grid.push(dataObjtable);
      if (this.grid?.length > 0) {
        let body = this.gestorInfo;
        body.residuosGestionados = this.grid;
        this.gestorInfo.numeroAutorizacion =
          this.gestorInfo.numeroAutorizacion ?? '';
        this.gestorInfo.numInscripcion1 = this.gestorInfo.numInscripcion1 ?? '';
        this.gestorInfo.numInscripcion2 = this.gestorInfo.numInscripcion2 ?? '';
        this.gestorInfo.numInscripcion3 = this.gestorInfo.numInscripcion3 ?? '';
        this.gestoresResiduosControllerService
          .editById4(this.gestorInfo.id!, body)
          .subscribe({
            next: (response: any) => {
              this.snackBarSrv.showSnackBar(
                'Los datos se han guardado correctamente.',
                'success'
              );
              this.refrescarGestorEvent.emit(true);
              this.formGroup.reset();
            },
            error: (err) => {},
          });
      } else {
        this.snackBarSrv.showSnackBar(
          'Al menos debe tener un registro de residuos gestionado',
          'error'
        );
      }
    } else {
      this.grid.push(dataObjtable);
      this.grid = [...this.grid];
      this.formGroup.reset();
    }
  }

  canAccessAgregar(): boolean {
    const resultAuth =
      this.authService.can('poseedores-gestor-prevision', 'create') &&
      this.gestorInfo.gestoresResiduosEstado &&
      [1, 2].includes(this.gestorInfo.gestoresResiduosEstado?.id);

    return resultAuth;
  }

  action(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarPrevision(event);
    } else if (event.nameButton == 'dar_baja') {
      this.opendDialgDarBajaResiduo(event);
    }
  }
  confirmacionEliminarPrevision(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la autorizacion del residuo?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (
          !(
            this.gestorInfo.gestoresResiduosEstado?.id == 1 &&
            this.authService.hasCurrentUserRole('PO-GESTOR')
          )
        ) {
          let body = this.gestorInfo;
          this.grid = this.grid.filter((x: any) => x.id !== element.id);
          body.residuosGestionados = this.grid;
          this.gestoresResiduosControllerService
            .editById4(this.gestorInfo?.id, body)
            .subscribe({
              next: (response: any) => {
                this.snackBarSrv.showSnackBar(
                  'Se ha eliminado correctamente el residuo',
                  'success'
                );
                this.refrescarGestorEvent.emit(true);
              },
              error: (err) => {},
            });
        } else {
          this.snackBarSrv.showSnackBar(
            `Se ha eliminando correctamente`,
            'success'
          );
          this.grid = this.grid.filter((x: any) => x.id !== element.id);
        }
      }
    });
  }

  showClearButton(formName: string): boolean {
    return this.formGroup.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formGroup.get(formName)?.setValue(null);
  }

  getComboOperacionGestion() {
    this.operacionGestionControllerService
      .findAll7(
        {},
        {
          page: 0,
          size: 500,
          sort: [],
        }
      )
      .subscribe({
        next: (data: any) => {
          this.operacionArray = data?.datos;
        },
        error: () => {},
      });
  }

  finalizar() {
    if (this.grid?.length > 0) {
      let body = this.gestorInfo;
      body.residuosGestionados = this.grid;
      body.gestoresResiduosEstado.id = 2;
      this.gestoresResiduosControllerService
        .editById4(this.gestorInfo.id!, body)
        .subscribe({
          next: (response: any) => {
            this.snackBarSrv.showSnackBar(
              'Los datos se han guardado correctamente. El gestor de residuos se ha actualiza a estado Completado. Ya puede comenzar con sus aportaciones DIS de sus empresas',
              'success'
            );
            this.router.navigate(['/initial-page']);
          },
          error: (err) => {},
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Al menos debe tener un registro de residuos gestionado',
        'error'
      );
    }
  }

  canAccessAll(list: any) {
    const resultdown = this.authService.can(
      'poseedores-gestor-prevision',
      'update-baja'
    );
    const resultDelete = this.authService.can(
      'poseedores-gestor-prevision',
      'delete'
    );

    const listPermission = list.map((objeto: any) => {
      let fechaMDesde = moment(new Date(objeto.fechaDesde)).format(
        'DD/MM/YYYY'
      );
      let fechaMHasta =
        objeto.fechaHasta != null
          ? moment(new Date(objeto.fechaHasta)).format('DD/MM/YYYY')
          : null;
      objeto.fechaMDesde = fechaMDesde;
      objeto.fechaMHasta = fechaMHasta;
      const estadoUpdatedDown =
        this.gestorInfo?.gestoresResiduosEstado &&
        [2].includes(this.gestorInfo?.gestoresResiduosEstado?.id!);
      const estadoEliminar =
        this.gestorInfo.gestoresResiduosEstado &&
        [1].includes(this.gestorInfo?.gestoresResiduosEstado?.id!);
      let descripcionMCodLer =
        objeto.codigoLer.tipoCodigo + ' ' + objeto.codigoLer.descripcion;
      let operacionMGestion =
        objeto.operacionGestion.codigo +
        ' ' +
        objeto.operacionGestion.descripcion;

      return {
        ...objeto,
        descripcionMCodLer: descripcionMCodLer,
        operacionMGestion: operacionMGestion,
        canAccessDown:
          resultdown && estadoUpdatedDown && objeto.fechaHasta == null,
        canAccessDelete: resultDelete && estadoEliminar,
      };
    });

    return listPermission;
  }

  opendDialgDarBajaResiduo(event: any) {
    const dialogRef = this.dialog.open(
      WasteManagersAuthorizedDownDialogComponent,
      {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          objetoResiduo: event,
          gestorInfo: this.gestorInfo,
        },
        disableClose: true,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refrescarGestorEvent.emit(true);
      }
    });
  }
}
