import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidateService {
  nifPattern: string =
    '(([A,B,C,D,E,F,G,H,J,U,V]\\d{8})|([P,Q,R,S,N,W]\\d{7}[A-Z]))';
  dniPattern: string = '\\d{8}[A-Z]';
  niePattern: string = '[X,Y,Z]\\d{7}[A-Z]';
  emailPattern: string = '[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}';
  numberPattern: string = '[0-9]*';
  documentNumber: string =
    '([X,Y,Z]\\d{7}[A-Z])|(\\d{8}[A-Z])|(([A,B,C,D,E,F,G,H,J,U,V]\\d{8})|([P,Q,R,S,N,W]\\d{7}[A-Z]))';
  numericPattern: string = '^[0-9]+$';
  lettersOnlyPattern: string = '^[a-zA-Z]+$';
  fiveDigitsPattern: string = '^[0-9]{1,5}$';
  ibanpattern: string = '^[A-Z]{2}\\d{22}$';
  numeric1DecimalMax100 = '([0-9]{1,3})([,][0-9]{1})?$';
  twoDecimals = '^[0-9]*(\\.[0-9]{0,2})?$';
  tenDigits ='^\\d{10}$';

  constructor() {}
}
