import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DocumControllerService,
  DocumentoModel,
  PoseedorControllerService,
  PoseedorDTO,
  PuntoRecogidaControllerService,
} from 'api-rest';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import swal from 'sweetalert2';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import moment from 'moment';
import { Router } from '@angular/router';
import { CollectionPointsDocumentsDialogComponent } from '../collection-points-documents-dialog/collection-points-documents-dialog.component';

const TIPO_DOC_CONTRATO = 1;
const TIPO_DOC_FACTURA = 2;
const TIPO_DOC_CERTIFICADO = 3;

@Component({
  selector: 'app-collection-points-documents',
  templateUrl: './collection-points-documents.component.html',
  styleUrls: ['./collection-points-documents.component.scss'],
})
export class CollectionPointsDocumentsComponent implements OnInit {
  @Input() puntoRecogidaInfo!: any;
  @Output() public refrescarPuntosRecogidaEvent = new EventEmitter<any>();

  containerPuntosRecogida: string = this.environment.documentContainerCollectionPoints;

  documentos!: DocumentoModel[];
  gridArray: any = [];
  headArray: any = [
    {
      Head: 'Tipo',
      FieldName: 'tipoDocumento',
      Attribute: 'descripcion',
    },
    {
      Head: 'Descripción',
      FieldName: 'descripcion',
      Tooltip: true,
      Maxtext: 30,
    },
    {
      Head: 'Fecha creación',
      FieldName: 'fechaCreacion',
      Tooltip: true,
    },
    {
      Head: 'Fecha ult. modificación',
      FieldName: 'fechaModificacion',
    },
    {
      Head: 'Modificado por',
      FieldName: 'usuarioModificacion',
      Tooltip: true,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'donwload',
          icon: 'download',
          toolTip: 'Descargar',
          show: { params: 'canAccessDonwload', accion: 'donwload' },
        },
        {
          nameButton: 'upload',
          icon: 'upload',
          toolTip: 'Subir',
          show: { params: 'canAccessUpload', accion: 'upload' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '8',
      permanent: true,
    },
  ];
  constructor(
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    public dialog: MatDialog,
    private downloadSrv: DownloadService,
    private documControllerSrv: DocumControllerService,
    private router: Router,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private puntoRecogidaControllerService: PuntoRecogidaControllerService
  ) {}

  ngOnInit(): void {
    this.cargarDocumentos();
  }

  cargarDocumentos() {
    this.spinnerSrv.loadingShow();
    this.documControllerSrv
      .listDocs('punto_recogida', this.puntoRecogidaInfo.id!)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.gridArray = this.canAccessAll(response).filter((doc: DocumentoModel) =>
            [1, 2, 3, 4, 5].includes(doc.tipoDocumento?.id!)
          );
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al consultar los documentos',
            'error'
          );
        },
      });
  }

  canAccessAll(list: any) {
    const descargarView = this.authService.can(
      'ptosrecogida-documentos',
      'download'
    );
    const deleteView = this.authService.can(
      'ptosrecogida-documentos',
      'delete'
    );
    const updateView = this.authService.can(
      'ptosrecogida-documentos',
      'update'
    );

    const listPermission = list.map((objeto: any) => {
      let fechaFormato = moment(new Date(objeto.fechaCreacion)).format(
        'DD/MM/YYYY'
      );
      let fechaModificacion = moment(new Date(objeto.fechaModificacion)).format(
        'DD/MM/YYYY'
      );
      const doc = [
        TIPO_DOC_CONTRATO,
        TIPO_DOC_FACTURA,
        TIPO_DOC_CERTIFICADO,
      ].includes(objeto?.tipoDocumento?.id);
      objeto.fechaCreacion = fechaFormato;
      objeto.fechaModificacion = fechaModificacion;
      let hayFichero = objeto?.contenedor ? true : false;

      return {
        ...objeto,
        canAccessUpload: updateView && !hayFichero,
        canAccessDonwload: descargarView && hayFichero,
        canAccessDelete: deleteView && !doc,
      };
    });

    return listPermission;
  }

  action(item: any) {
    if (item.nameButton == 'donwload') {
      this.descargar(item);
    } else if (item.nameButton == 'upload') {
      this.openFileSelector(item);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }
  openFileSelector(item: any) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';
    fileInput.addEventListener('change', (event) =>
      this.handleFileSelection(event, item)
    );
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  }

  handleFileSelection(event: any, item: any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.actualizar(selectedFile, item);
    }
  }

  abrirNuevoDocumento() {
    const dialogRef = this.dialog.open(
      CollectionPointsDocumentsDialogComponent,
      {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          puntoRecogidaInfo: this.puntoRecogidaInfo,
        },
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cargarDocumentos();
      }
    });
  }

  descargar(element: any) {
    this.spinnerSrv.loadingShow();
    this.documControllerSrv.downloadDoc(element.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadSrv.downloadBlob(response, element.nombre);
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al descargar el documento',
          'error'
        );
      },
    });
  }

  actualizar(archivo: any, element: any): void {
    // Deprecated: no se está utilizando, si se vuelve a usar es necesario actualizar la API
    let id = this.puntoRecogidaInfo.id! ?? null;
    this.spinnerSrv.loadingShow();
    this.documControllerSrv
      .updateDoc(
        element.id,
        archivo?.name,
        archivo,
        this.containerPuntosRecogida,
        element.tipoDocumento.id,
        id,
        'punto_recogida',
        element?.descripcion
      )
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha subido correctamente el documento',
            'success'
          );
          this.cargarDocumentos();
          this.actualizarEstado(element);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al subir el documento',
            'error'
          );
        },
      });
  }

  confirmacionEliminar(element?: any): void {
    swal
      .fire({
        text: '¿Desea eliminar Documento?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.eliminarDocumento(element);
        }
      });
  }

  eliminarDocumento(element: any) {
    this.spinnerSrv.loadingShow();
    this.documControllerSrv.deleteDoc(element.id).subscribe({
      next: (response) => {
        this.snackBarSrv.showSnackBar(
          'Se ha eliminado correctamente el documento',
          'success'
        );
        this.spinnerSrv.loadingHide();
        this.cargarDocumentos();
      },
      error: (err) => {
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al eliminar el documento',
          'error'
        );
        this.spinnerSrv.loadingHide();
      },
    });
  }
  actualizarEstado(documento: any) {
    if (
      this.puntoRecogidaInfo?.estado?.id === 3 &&
      documento?.tipoDocumento?.id == TIPO_DOC_CONTRATO &&
      documento.descripcion === 'Contrato pendiente de firma'
    ) {
      this.spinnerSrv.loadingShow();
      this.puntoRecogidaControllerService
        .actualizarEstadoPtoRecogida(this.puntoRecogidaInfo.id!, 4)
        .subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            this.refrescarPuntosRecogidaEvent.emit(true);
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al actualizar el estado puntos recogida',
              'error'
            );
          },
        });
    }
  }

  canAccessNuevoDoc(): boolean {
    return this.authService.can('ptosrecogida-documentos', 'create');
  }
}
