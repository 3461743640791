<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">Detalle de la solicitud - ID: {{ infoRequests.id }}</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <form class="form-modal py-3" [formGroup]="dataForm">
      <div class="title-group">Información del Punto de Recogida </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="ptoRecogida">Punto de recogida <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field-width" appearance="outline">
            <input type="text" matInput formControlName="ptoRecogida" id="ptoRecogida" placeholder="Punto de recogida"
              value="" maxlength="255">
            <mat-error *ngIf="controlHasError( 'ptoRecogida', 'maxlength')">Máximo 255 caracteres</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="contactoPtoRecogida">Contacto</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="contactoPtoRecogida" id="contactoPtoRecogida"
                placeholder="Contacto" value="">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="direccionPtoRecogida">Dirección</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="direccionPtoRecogida" id="direccionPtoRecogida"
                placeholder="Dirección" value="">
            </mat-form-field>
          </div>
        </div>
      </div>


      <div class="title-group">Información del gestor </div>

      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="contactoPtoRecogida">Gestor</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="gestor" id="gestor" placeholder="Gestor" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="nimaGestor">NIMA Gestor </label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="nimaGestor" id="nimaGestor" placeholder="Nima" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-3">
          <label for="contactoGestor">Contacto </label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="contactoGestor" id="contactoGestor" placeholder="Contacto"
                value="">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row form">
        <div class="col-sm flex-grow-">
          <label for="direccionGestor">Dirección </label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="direccionGestor" id="direccionGestor" placeholder="Dirección"
                value="">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="title-group">Información de la solicitud </div>

      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="estadoSolicitud">Estado </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="estadoSolicitud" id="estadoSolicitud" placeholder="Estado"
                value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="fechaSolicitud">Fecha de solicitud</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="fechaSolicitud" id="fechaSolicitud"
                placeholder="Fecha solicitud" value="">
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-1">
          <ng-container *ngIf="validatePrEnvGest4()">
            <label for="fechaRecogida">Fecha de cancelación/rechazo <span *ngIf="flagPrGestor2"
                class="oblig">*</span></label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field-width" appearance="outline">
                <input matInput [matDatepicker]="datepickerC" formControlName="fechaCancelacion"
                  placeholder="Fecha de cancelación/rechazo">
                <mat-datepicker-toggle matIconSuffix [for]="datepickerC"></mat-datepicker-toggle>
                <mat-datepicker #datepickerC>
                </mat-datepicker>
                <mat-error *ngIf="controlHasError('fechaCancelacion', 'matDatepickerParse')">Formato incorrecto
                </mat-error>
                <mat-error *ngIf="controlHasError('fechaCancelacion', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <div class="col-sm flex-grow-1">
          <ng-container *ngIf="validatePrEnvGest4()">
            <label for="motivo">Motivo de la cancelación/rechazo</label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field-width" appearance="outline">
                <input type="text" matInput formControlName="motivo" id="motivo" placeholder="Motivo" value="" maxlength="255">
                <mat-error *ngIf="controlHasError( 'motivo', 'maxlength')">Máximo 255 caracteres</mat-error>
              </mat-form-field>
            </div>
          </ng-container>
          <div class="col-sm flex-grow-1">
          </div>
        </div>

      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="observacionesPto">Observaciones Punto de Recogida</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="observacionesPto" id="observacionesPto"
                placeholder="Observaciones" value="" maxlength="255">
              <mat-error *ngIf="controlHasError( 'observacionesPto', 'maxlength')">Máximo 255 caracteres</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="title-group pt-3">Información solicitud recogida </div>

      <div class="row form">

        <div class="col-sm flex-grow-2">
          <label for="codigoLer">Código LER y descripción de ENVALORA <em
            *ngIf="dataForm.get('codigoLer')?.value?.peligrosidad === true" class="fas fa-skull-crossbones"
            style="color: red;" matTooltip="Envase de tipo peligroso"></em></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="codigoLer" id="codigoLer" placeholder="CodigoLer" value="">
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-1">
          <label for="acondicionamiento">Acondicionamiento</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="acondicionamiento" id="acondicionamiento"
                placeholder="Acondicionamiento" value="">
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-1">
          <label for="cantidadUnidades">Cantidad (unid)</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="cantidadUnidades" id="cantidadUnidades"
                placeholder="cantidad Unidades" value="">
            </mat-form-field>
          </div>
        </div>

      </div>


      <div class="title-group pt-3">Información solicitud suministro</div>

      <div class="row form">

        <div class="col-sm flex-grow-1">
          <label for="cantidadSuministro">Cantidad de suministro (unid)</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="cantidadSuministro" id="cantidad"
                placeholder="cantidad Suministro" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="acondicionamientoSolicitud">Acondicionamiento</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="acondicionamientoSolicitud" id="acondicionamientoSolicitud"
                placeholder="Acondicionamiento" value="">
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-1"></div>
        <div class="col-sm flex-grow-1"></div>
      </div>

      <ng-container *ngIf="this.infoRequests?.estado?.id>=1">
        <div class="title-group pt-3">Información de la recogida</div>


        <div class="row form">
          <div class="col-sm flex-grow-1">
            <label for="fechaSugerida">Fecha sugerida para la recogida</label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field-width" appearance="outline">
                <input matInput [matDatepicker]="datepickerFg" formControlName="fechaSugerida" placeholder="Fecha">
                <mat-datepicker-toggle matIconSuffix [for]="datepickerFg"></mat-datepicker-toggle>
                <mat-datepicker #datepickerFg>
                </mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm flex-grow-1">
            <label for="fechaPrevista">Fecha prevista <span *ngIf="flagPrGestor1" class="oblig">*</span></label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field-width" appearance="outline">
                <input matInput [matDatepicker]="datepickerFp" formControlName="fechaPrevista" placeholder="Fecha">
                <mat-datepicker-toggle matIconSuffix [for]="datepickerFp"></mat-datepicker-toggle>
                <mat-datepicker #datepickerFp>
                </mat-datepicker>
                <mat-error *ngIf="controlHasError('fechaPrevista', 'matDatepickerParse')">Formato incorrecto
                </mat-error>
                <mat-error *ngIf="controlHasError('fechaPrevista', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm flex-grow-1">
            <label for="fechaRecogida">Fecha recogida <span *ngIf="flagPrGestor2" class="oblig">*</span></label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field-width" appearance="outline">
                <input matInput [matDatepicker]="datepickerR" formControlName="fechaRecogida" placeholder="Fecha">
                <mat-datepicker-toggle matIconSuffix [for]="datepickerR"></mat-datepicker-toggle>
                <mat-datepicker #datepickerR>
                </mat-datepicker>
                <mat-error *ngIf="controlHasError('fechaRecogida', 'matDatepickerParse')">Formato incorrecto
                </mat-error>
                <mat-error *ngIf="controlHasError('fechaRecogida', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row form">
          <div class="col-sm flex-grow-1" style="flex-basis: 52%;">
            <label for="codigoLerRecogida">Código LER y descripción de ENVALORA <span *ngIf="validatePrGestor2()"
                class="oblig">*</span></label>
            <div class="custom-mat-form-field-width">
              <mat-form-field class="custom-mat-form-field-width" appearance="outline">
                <mat-select formControlName="codigoLerRecogida" id="codigoLerRecogida"
                  placeholder="Código LER y descripción de ENVALORA">
                  <mat-option *ngFor="let item of codigoLerArray" [value]="item.id">
                    {{ item?.tipoCodigo }} {{ item?.descripcion }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="controlHasError('codigoLerRecogida', 'required')">Campo obligatorio</mat-error>
            </div>
          </div>
          <div class="col-sm flex-grow-1">
            <div style="display: flex; align-items: center;">
              <label for="kgReales">Kg reales <span *ngIf="flagPrGestor2" class="oblig">*</span></label>
              <div class="tool-help" matTooltip="Corresponde a la recogida neta de residuo" data-placement="top"
                title="">
                <span>?</span>
              </div>
            </div>

            <div class="custom-mat-field">
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input type="text" matInput formControlName="kgReales" id="kgReales" placeholder="Kg" value=""
                  appNumericPoint>
                <mat-error *ngIf="controlHasError('kgReales', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row form">
          <div class="col-sm flex-grow-1">
            <label for="observacionesGestor">Observaciones Gestor</label>
            <div class="custom-mat-field">
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input type="text" matInput formControlName="observacionesGestor" id="observacionesGestor"
                  placeholder="Observaciones" value="" maxlength="255">
                <mat-error *ngIf="controlHasError( 'observacionesGestor', 'maxlength')">Máximo 255 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="this.infoRequests?.estado?.id>=2" class="title-group pt-3">Aportación de DI </div>

      <div *ngIf="this.infoRequests?.estado?.id>=2" class="row form">
        <div class="col-sm flex-grow-1">
          <label for="documentoDi">Nº Documento de Identificación / Full de Seguiment <span *ngIf="flagPrGestor2"
              class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="documentoDi" id="documentoDi" placeholder="Documento"
                value="" maxlength="255">
              <mat-error *ngIf="controlHasError( 'documentoDi', 'maxlength')">Máximo 255 caracteres</mat-error>
              <mat-error *ngIf="controlHasError('documentoDi', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex flex-column col-sm flex-grow-1">

          <label for="documento">Documento <span
              *ngIf="(canViewRol('PR-GESTOR') || canViewRol('PR-ENV-GEST')) && this.infoRequests?.estado?.id==2"
              class="oblig">*</span></label>
          <div class="custom-mat-field">
            <div class="attach">
              <div *ngIf="(canViewRol('PR-GESTOR') || canViewRol('PR-ENV-GEST')) && this.infoRequests?.estado?.id==2">
                <input #file type="file" (change)="file && file.files && attachAdjunto(file.files[0])" class="hide"
                  id="adjuntar" name="adjuntar">
                <mat-icon (click)="file.click()" class="ml-2 color-custom pointer-cursor"
                  matTooltip="Adjuntar documento">
                  upload_file</mat-icon>
              </div>
              <div>
                <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="documento != undefined"
                  class="centrar archivo">
                  <div><span class="sprite-icon ico-adjuntar"></span><span class="nombre-archivo">{{documento.name}}
                    </span>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="(this.infoRequests?.documento != undefined && documento === undefined &&
                  this.infoRequests?.documento != null)" class="centrar
                  archivo">
                  <div><span class="sprite-icon ico-adjuntar"></span><span
                      class="nombre-archivo">{{this.infoRequests.documento!.nombre}}
                    </span>
                    <mat-icon (click)="descargar(this.infoRequests.documento)"
                      class="ml-2 padding-top-down color-custom pointer-cursor" matTooltip="Descargar">
                      download</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm flex-grow-1">
          <label *ngIf="(canViewRol('PR-GESTOR') && this.infoRequests?.autofactura)"
            for="autofactura">Prefactura</label>
          <div fxLayout="row" fxLayoutAlign="space-between center"
            *ngIf="(canViewRol('PR-GESTOR') && this.infoRequests?.autofactura)" class="centrar archivo">
            <div><span class="sprite-icon ico-adjuntar"></span><span
                class="nombre-archivo">{{this.infoRequests?.autoFactura?.nombre}}
              </span>
              <mat-icon (click)="descargar(this.infoRequests?.autofactura)" matTooltip="Descargar"
                class="ml-2 color-custom pointer-cursor">
                download</mat-icon>
            </div>
          </div>
        </div>

      </div>

    </form>
  </div>

  <div class="fixed-footer">
    <div class="modal-footer actions">
      <div class="note">* Campos Obligatorios</div>
      <div class="button-group">
        <button type="button" (click)="closeDialog()" class="btn btn-outline-primary" data-dismiss="modal"
          aria-label="Close">Cancelar</button>
        <button *ngIf="canSaveRequests()" type="button" class="btn btn-primary" data-dismiss="modal"
          aria-label="Guardar" (click)="saveData()">Guardar</button>
        <button *ngIf="canSaveCourseRequests()" type="button" class="btn btn-primary" data-dismiss="modal"
          aria-label="Guardar" (click)="saveDataCourse()">Guardar y pasar a en curso</button>
        <button *ngIf="canSaveFinalRequests()" type="button" class="btn btn-primary" data-dismiss="modal"
          aria-label="Guardar" (click)="saveDataFinal()">Guardar y finalizar</button>
      </div>
    </div>
  </div>

</div>
