<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<!-- MIGAS DE PAN Y TÍTULO -->
<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">SDDR Abierto</li>
          <li class="breadcrumb-item active"><a href="#/open-sddr/requests-remain-search">Solicitudes de envase SDDR CA</a>
        </ol>
      </nav>
      <div class="content-title">
        <h2 class="title-pag">Búsqueda de solicitudes de envase SDDR CA</h2>
        <button type="button" class="btn btn-primary" data-toggle="modal" (click)="openModalDetailRequest()"
                *ngIf="canAccessCrearSolicitud()">
          <span class="icon-add"></span>Nueva solicitud
        </button>
      </div>
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->
<!-- CONTENIDO -->
<div class="container-form" *ngIf="!ocultarBuscador">
  <!-- Formulario de búsqueda -->
  <form class="container form-search py-3 padding-form" [formGroup]="searchForm">
    <div class="row-fields">
      <div class="field field-grow" *ngIf="canAccessEnvalora()">
        <label for="gestor">Gestor / Acondicionador </label>
        <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="gestoresEndpoint"
                                  [descripcion]="getGestorDescription"
                                  [formControlNameSelect]="getFormControl('gestorAcondicionador')"
                                  [objQueryName]="getObjectQuery"
                                  [placeHolder]="'-- Gestor / Acondicionador --'"
                                  [customClass]="rightHolder('gestorAcondicionador')">
        </lib-ge-select-pagination>
      </div>
      <div class="field " [ngClass]="canAccessEnvalora() ? ' field-22': ' field-grow'">
        <label for="idRetirada">ID. Retirada </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input type="text" matInput formControlName="idRetirada" id="idRetirada" placeholder="ID. Retirada"
                 value="" maxlength="10" (keypress)="onlyNumberKey($event)">
        </mat-form-field>
      </div>
      <div class="field field-22">
        <label for="tipoEnvase">Tipo de envase </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="tipoEnvase" id="tipoEnvase" placeholder="-- Tipo de envase --">
            <mat-option *ngFor="let tipo of tiposEnvases" [value]="tipo.id">
              {{ tipo?.descripcion }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('tipoEnvase')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'tipoEnvase')">
                        <span aria-hidden="true" class="ng-clear">×</span>
                    </span>
        </mat-form-field>
      </div>
      <div class="field " [ngClass]="canAccessEnvalora() ? ' field-22': ' field-33'">
        <label for="subtipoEnvase">Subtipo de envase </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="subtipoEnvase" id="subtipoEnvase" placeholder="-- Subtipo de envase --">
            <mat-option *ngFor="let tipo of subtiposEnvases" [value]="tipo.id">
              {{ tipo?.descripcion }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('subtipoEnvase')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'subtipoEnvase')">
                        <span aria-hidden="true" class="ng-clear">×</span>
                    </span>
        </mat-form-field>
      </div>
    </div>
    <div class="row-fields">
      <div class="field field-grow">
        <label for="loteEnvase">Lote envase</label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input type="text" matInput formControlName="loteEnvase" id="loteEnvase" placeholder="Lote envase"
                 value="">
        </mat-form-field>
      </div>

      <div class="field field-22">
        <label for="fechaDesde">Fecha solicitud desde </label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="datepickerDesde" formControlName="fechaDesde">
          <mat-datepicker-toggle matIconSuffix [for]="datepickerDesde"></mat-datepicker-toggle>
          <mat-datepicker #datepickerDesde>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaDesde', 'matDatepickerParse')">Formato incorrecto
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field field-22">
        <label for="fechaHasta">Fecha solicitud hasta </label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="fechaHasta" formControlName="fechaHasta">
          <mat-datepicker-toggle matIconSuffix [for]="fechaHasta"></mat-datepicker-toggle>
          <mat-datepicker #fechaHasta>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaHasta', 'matDatepickerParse')">Formato incorrecto
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field field-22">
        <label for="estado">Estado </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="estado" id="estado" placeholder="-- Estado --">
            <mat-option *ngFor="let tipo of estados" [value]="tipo.id">
              {{ tipo?.descripcion }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('estado')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'estado')">
                        <span aria-hidden="true" class="ng-clear">×</span>
                    </span>
        </mat-form-field>
      </div>

    </div>
    <div class="row-fields">

    </div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" (click)="limpiar()">Limpiar</button>
      <button type="button" class="btn btn-primary" (click)="search()">Buscar</button>
    </div>
  </form>
</div>
<!--fin fomulario-->
<!--Resultados búsqueda-->
<div class="bg-second-table-info pt-3 pb-5 " *ngIf="!ocultarBuscador">
  <div *ngIf="flagSearch; else messageClear" class="container">
    <div class="actions-table">
      <div class=""><span class="align-middle tit-table">{{ totalSearch }} registros
            encontrados</span></div>
      <div class="botones-tabla">
        <!--<div class="d-inline" *ngIf="canAccessCrearAutofactura()">
          <div class="dropdown show">
            <a class="btn btn-outline-primary" role="button" aria-haspopup="true" aria-expanded="false"
               (click)="confirmacionAutofactura()">
              <span>Crear prefactura </span></a>
          </div>
        </div>-->
        <div class="d-inline" *ngIf="canAccessExportar()">
          <div class="dropdown show">
            <a class="btn btn-outline-primary" role="button" aria-haspopup="true" aria-expanded="false"
               (click)="exportarCsv()">
              <span>Exportar</span></a>
          </div>
        </div>
      </div>

    </div>
    <div class="card-tabla-proy">
      <div class="card-body">
        <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
          <div class="datatable-container table-responsive">

            <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (actionButton)="action($event)">
            </lib-ge-table>
            <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
              <div class="d-flex mt-2">
                <ngb-pagination [pageSize]="pageSize" [collectionSize]="totalElements" [(page)]="pageNumber"
                                (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination"
                                size="sm">
                </ngb-pagination>
              </div>

              <div class="mt-2 selectPage">
                <label for="itemsPerPage">Mostrar:</label>
                <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                  <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #messageClear>
    <div class="mensaje-container">
      <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
        formulario.</p>
    </div>
  </ng-template>
</div>
<!-- fin resultados búsqueda-->
<!-- FIN CONTENIDO -->
