  <!-- MIGAS DE PAN Y TÍTULO -->
  <div class="bg-second-migas">
    <div class="container mt-cab-fixed">
      <div class="pre-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Administración</li>
            <li class="breadcrumb-item">Usuarios</li>
            <li class="breadcrumb-item active"><a href="index.html">Búsqueda de usuarios</a></li>
          </ol>
        </nav>
        <div class="content-title">
          <h2 class="title-pag">Búsqueda de Usuarios</h2>
          <button type="button" class="btn btn-primary" data-toggle="modal"
            (click)="openModalUser({ enabled: true })"><span class="icon-add"></span>Nuevo Usuario</button>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN MIGAS DE PAN Y TÍTULO -->
  <!-- CONTENIDO -->

  <div class="container-form">
    <!-- Formulario de búsqueda -->
    <form class="container form-search py-3" [formGroup]="searchForm">
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="firstName">Nombre</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="text" matInput formControlName="firstName" id="firstName" placeholder="Nombre" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="lastName">Apellidos</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="text" matInput formControlName="lastName" id="lastName" placeholder="Apellidos" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="email">Email</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="text" matInput formControlName="email" id="email" placeholder="Email" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="userRole">Rol</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="userRole" id="userRole" placeholder="-- Rol de usuario --">
                <mat-option *ngFor="let role of roles" [value]="role.name">
                  {{ role.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="userType">Tipo de Usuario</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="userType" id="userType" placeholder="-- Tipo de usuario --">
                <mat-option *ngFor="let element of userTypes" [value]="element.id">
                  {{ separarCamelCase(element.name) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="entidadAsociada">Entidad asociada</label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination class="lib-ge-select"  descripcion="razonSocial"
                [endPointFilter]="entidadAdociadaEndpoint" objQueryName="razonSocial" placeHolder="-- Entidad asociada --"
                [formControlNameSelect] = "getEntidadAsociadoFormControl()" [modelSelected]="this.searchForm.value.entidadAsociada"
                [updateList]="updateList">
              </lib-ge-select-pagination>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="enabled">Activo</label>
          <div class="custom-mat-field">
            <mat-checkbox formControlName="enabled" id="enabled" color="primary">
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="button-group">
        <button type="button" class="btn btn-outline-primary" (click)="clearSearcher()">Limpiar</button>
        <button type="button" class="btn btn-primary" (click)="searchData()">Buscar</button>
      </div>
    </form>
    <!-- FIN Formulario de búsqueda -->
  </div>
  <div class="bg-second-table-info pt-3 pb-5">
    <div *ngIf="flagSearch; else messageClear" class="container">
      <div class="actions-table">
        <div class=""><span class="align-middle tit-table">{{ recordsTotal }} registros encontrados</span></div>
        <div class="text-right">
          <div class="d-inline">
            <!-- <div class="d-inline boton-nuevo">
              <button type="button" class="d-inline btn btn-primary" data-toggle="modal" data-target="#NuevoUsuario"
              (click)="openModalUser({ enabled: true })">Nuevo usuario</button>
            </div> -->
            <div class="d-inline dropdown show">
              <a (click)="exportarExcel()" class="btn btn-outline-primary" role="button" aria-haspopup="true"
                 aria-expanded="false">
                <span class="mr-3">Exportar</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-tabla-proy">
        <div class="card-body">
          <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
            <div class="datatable-container table-responsive">
              <!-- TABLA Listado con resultados de búsqueda -->
              <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (checked)="checked($event)"
                (actionButton)="action($event)"></lib-ge-table>
              <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
                <div class="d-flex mt-2">
                  <ngb-pagination [pageSize]="pageSize" [collectionSize]="recordsTotal" [(page)]="pageNumber"
                    (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
                  </ngb-pagination>
                </div>

                <div class="mt-2 selectPage">
                  <label for="itemsPerPage">Mostrar:</label>
                  <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                    <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <!-- FIN TABLA Listado con resultados de búsqueda -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #messageClear>
      <div class="mensaje-container">
        <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
          formulario,</p>
        <p class="mensaje-linea">o cree un nuevo registro desde el botón "Nuevo Usuario"</p>
      </div>
    </ng-template>
  </div>
