import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {EmpusaAuthenticationService} from '@empusa/empusa-core';
import {SnackbarService} from '../../../services/snackbar.service';
import {SpinnerService} from '../../../services/spinner.service';
import {Combo, ComboService} from '../../../services/combo.service';
import {
  SolicitudReutilizacionControllerService,
  SolicitudReutilizacionEstadoControllerService,
  SolicitudReutilizacionEstadoDTO,
  SolicitudReutilizacionFilterDTO,
  SubTipoEnvaseControllerService,
  SubTipoEnvaseDTO,
  SubTipoEnvaseFilterDTO,
  TipoEnvasesControllerService,
  TipoEnvasesDTO,
  UsuarioSDDRCaDTO
} from 'api-rest';
import {zip} from 'rxjs';
import moment from 'moment';
import {DatePipe} from '@angular/common';
import {OpenSddrService} from '../../../services/open-sddr.service';
import {DownloadService} from '../../../services/download.service';
import Swal from 'sweetalert2';
import {OpenSsdrReuseDialogComponent} from '../open-ssdr-reuse-dialog/open-ssdr-reuse-dialog.component';

@Component({
  selector: 'open-sddr-open-sddr-remain-search',
  templateUrl: './open-sddr-reuse-search.component.html',
  styleUrls: ['./open-sddr-reuse-search.component.scss']
})
export class OpenSddrReuseSearchComponent implements OnInit {
  formSearch!: FormGroup;
  tiposUsuarios!: Combo[];
  tiposUsuariosSddr!: UsuarioSDDRCaDTO[];
  tiposEnvases!: TipoEnvasesDTO[];
  subtiposEnvases!: SubTipoEnvaseDTO[];
  usosEnvases!: Combo[];
  estados!: SolicitudReutilizacionEstadoDTO[];
  flagSearch: boolean = false;
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  totalSearch: any = 0;
  ocultarBuscador: boolean = true;

  headArray: any = [
    {
      Head: 'ID',
      FieldName: 'id'
    },
    {
      Head: 'Tipo usuario',
      FieldName: 'tipoUsuario'
    }, {
      Head: 'Razón social',
      FieldName: 'razonSocial'
    }, {
      Head: 'Provincia',
      FieldName: 'provincia',
      renderValue: (item: any) => {
        let user = item.adherido ?? item.poseedor;
        // Primero "Física"
        let domicilio = user?.domicilios?.find((dom: any) => dom.tipoDireccion.descripcion === "Física");
        // Si no existe,"Social"
        if (!domicilio) {
          domicilio = user?.domicilios?.find((dom: any) => dom.tipoDireccion.descripcion === "Social");
        }
        // else null
        return domicilio?.provincia?.nombre || '';
      }
    }, {
      Head: 'Fecha solicitud',
      FieldName: 'fechaSolicitud',
      renderValue: (item: any) => this.datePipe.transform(item.fechaSolicitud, 'dd/MM/yyyy')
    },
    {
      Head: 'Tipo de envase ',
      FieldName: 'tipoEnvase',
      Attribute: 'descripcion'
    },
    {
      Head: 'Subtipo de envase',
      FieldName: 'subTipoEnvase',
      Attribute: 'descripcion'
    },
    {
      Head: 'Lote envase',
      FieldName: 'loteEnvase'
    },
    {
      Head: 'Nº de envases a reutilizar',
      FieldName: 'numeroEnvases'
    },
    {
      Head: '¿Uso del envase?',
      FieldName: 'usoEnvase',
      renderValue: (item: any) => this.usosEnvases.find(x => item.usoEnvase === x.id)?.name
    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcion'
    },
    {
      Head: 'Prefacturada',
      FieldName: 'autoFactura',
      renderValue: (item: any) => item.autoFactura ? 'SI' : 'NO'
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      check: this.canViewResource('SDDR-CA-ENV-GEST'),
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        }, {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'sendRequest',
              name: 'Enviar solicitud ',
              show: { params: 'canAccessSendReq', accion: 'sendRequest' },
            },
            {
              nameButton: 'acceptRequest',
              name: 'Aceptar solicitud',
              show: { params: 'canAccessAcceptReq', accion: 'acceptRequest' },
            },
            {
              nameButton: 'rejectRequest',
              name: 'Rechazar solicitud',
              show: { params: 'canAccessRejectReq', accion: 'rejectRequest' },
            }
          ],
        }
      ],
      width: '10.2',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  constructor(
    public spinnerSrv: SpinnerService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    private comboSrv: ComboService,
    private tipoEnvasesCtrSrv: TipoEnvasesControllerService,
    private solicitudReutilizacionCtrSrv: SolicitudReutilizacionControllerService,
    private subtipoEnvaseCtrSrv: SubTipoEnvaseControllerService,
    private solicitudReutilizacionEstadoCtrSrv: SolicitudReutilizacionEstadoControllerService,
    private datePipe: DatePipe,
    private openSddrSrv: OpenSddrService,
    private solicitudesReutilizacionCtrlSrv: SolicitudReutilizacionControllerService,
    private downloadSrv: DownloadService) {
    this.formSearch = this.formBuilder.group({
      tipoUsuario: [{ value: null, disabled: false }],
      idUsuario: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      usuarioSddr: [{ value: null, disabled: false }],
      tipoEnvase: [{ value: null, disabled: false }],
      subtipoEnvase: [{ value: null, disabled: false }],
      loteEnvase: [{ value: null, disabled: false }],
      usoEnvase: [{ value: null, disabled: false }],
      fechaDesde: [{ value: null, disabled: false }],
      fechaHasta: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
      autofacturada: [{ value: null, disabled: false }]
    });
  }

  ngOnInit(): void {
    this.cargarCombos();
    //Solo los SDDR_CA_ENV_GEST y SDDR_CA_ENV_CONS verán el campo provinciano
    this.headArray = !this.canViewResource('SDDR-CA-ENV-GEST') || !this.canViewResource('SDDR-CA-ENV-CONS')
      ? this.headArray
      : this.headArray.filter(
        (head: any) =>
          head.FieldName !== 'provincia'
      );

    //Si no puede crear prefactura se elimina el check
    if (!this.canCrearAutofactura()) {
      this.removeCheck(this.headArray)
    }

    this.buscar(true)
  }

  removeCheck(list: any[]) {
    list.forEach((item) => {
      if (item.FieldName === 'actions' && item.check) {
        delete item.check;
      }
    });
    this.headArray = list;
  }
  checked(event: any) {
    if (event.estado.id != 3) {
      this.snackBarSrv.showSnackBar(
        'Solo se permitirá prefacturar solicitudes en estado Aceptada',
        'error'
      );
      event.checked = null;
    }
  }

  cargarCombos(): void {
    this.tiposUsuarios = this.comboSrv.getTiposUsuarioSolicitud();
    this.usosEnvases = this.comboSrv.getUsosEnvases();
    const tipoEnvaseFilter = { sddrca: true };
    const subtipoEnvaseFilter: SubTipoEnvaseFilterDTO = { tipoEnvase: { sddrca: true } };
    this.spinnerSrv.loadingShow();
    zip(
      this.tipoEnvasesCtrSrv.findAll(tipoEnvaseFilter, { page: 0, size: 100 }),
      this.solicitudReutilizacionCtrSrv.findAllUsuariosAsociados(),
      this.subtipoEnvaseCtrSrv.listarSubTipoEnvases(subtipoEnvaseFilter, { page: 0, size: 100 }),
      this.solicitudReutilizacionEstadoCtrSrv.listarSolicitudReutilizacionEstados({}, { page: 0, size: 500 })
    ).subscribe({
      next: (responses: any[]) => {
        this.spinnerSrv.loadingHide();
        this.tiposEnvases = responses[0].datos;
        this.tiposUsuariosSddr = responses[1];
        this.subtiposEnvases = responses[2].datos;
        this.estados = responses[3].datos
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formSearch.get(formName)?.setValue(null);
  }
  showClearButton(formName: string): boolean {
    return this.formSearch.get(formName)?.value !== null;
  }
  controlHasError(controlName: string, errorName: string) {
    return (
      this.formSearch.controls[controlName]?.hasError(errorName) &&
      this.formSearch.controls[controlName]?.touched
    );
  }
  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.buscar();
    }
  }
  action(item: any) {
    if (item.nameButton == 'view') {
      this.abrirModal(item);

    } else if (item.nameButton == 'delete') {
      this.confirmacionBorrar(item);
    } else if (item.nameButton == 'sendRequest') {
      this.confirmacionEnviar(item)
    } else if (item.nameButton == 'acceptRequest') {
      this.confirmacionAceptar(item);
    } else if (item.nameButton == 'rejectRequest') {
      this.confirmacionRechazar(item)
    }
  }
  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.buscar();
  }
  limpiar(): void {
    this.formSearch.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  buscar(init?: boolean): void {
    let filtro: SolicitudReutilizacionFilterDTO;
    filtro = this.getBackendFilter();
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    this.spinnerSrv.loadingShow();
    this.openSddrSrv.buscarSolicitudesReutilizacion(filtro, {
      page: page, size: this.pageSize,
      sort: ['id,desc']
    }).subscribe({
      next: (data: any) => {
        this.spinnerSrv.loadingHide();
        if (data) {
          this.flagSearch = data?.datos?.length != 0 ? true : false;
          this.ocultarBuscador = ((this.canViewResource('AD-ADHERIDO') || this.canViewResource('PO-POSEEDOR')) && (!this.flagSearch && init == true));
          this.gridArray = this.canAccessAll(data?.datos);
          this.totalElements = data.paginacion?.totalElements ?? 0;
          this.totalSearch = this.gridArray.length;
        }
      },
      error: (error: any) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al buscar las solicitudes de reutilización',
          'error'
        );
      },
    });
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('sddr-ca-solicitudes-reutilizacion', 'view');
    const resultDelete = this.authService.can('sddr-ca-solicitudes-reutilizacion', 'update-delete');
    const resultSendRequest = this.authService.can('sddr-ca-solicitudes-reutilizacion', 'update-send');
    const resultAcceptRequest = this.authService.can('sddr-ca-solicitudes-reutilizacion', 'update-accept');
    const resultRejectRequest = this.authService.can('sddr-ca-solicitudes-reutilizacion', 'update-reject');

    const listPermission = list.map((objeto: any) => {
      //Si poseedor o adherido solo podré eliminar las solicitudes que estén dadas de alta por él mismo
      const adPoDelete = objeto.creadoPor == this.authService.getCurrentUserMail() && (this.canViewResource('AD-ADHERIDO') || this.canViewResource('PO-POSEEDOR'));
      const isGest = this.canViewResource('SDDR-CA-ENV-GEST');

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete: (resultDelete && isGest && objeto.estado.id === 1) || (resultDelete && adPoDelete && objeto?.estado?.id === 1),
        canAccessSendReq: resultSendRequest && objeto.creadoPor == this.authService.getCurrentUserMail() && objeto?.estado?.id === 1, //PO_POSEEDOR y AD_ADHERIDO (sólo podrán enviar aquellas solicitudes que estén dadas de alta por el mismo)
        canAccessAcceptReq: resultAcceptRequest && objeto?.estado?.id === 2,
        canAccessRejectReq: resultRejectRequest && objeto?.estado?.id === 2
      };
    });

    return listPermission;
  }

  abrirModal(solicitud?: any): void {
    let soloLectura: boolean = true;
    if (this.canEditar() && (solicitud?.estado?.id === 1 || !solicitud?.id)) {
      soloLectura = false;
    }

    const dialogRef = this.dialog.open(
      OpenSsdrReuseDialogComponent,
      {
        width: '1460px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          solicitudId: solicitud?.id,
          soloLectura: soloLectura,
          estados: this.estados
        },
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.buscar();
        }
      }
    );

  }
  exportarCsv(): void {
    let filter = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.solicitudesReutilizacionCtrlSrv.exportCSV1(filter)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.downloadSrv.downloadBlob(response, 'solicitudesReutilizacion_SDDR_CA.csv');
        },
        error: (err: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al exportar las solicitudes de reutilización.',
            'error'
          );
        },
      });
  }

  getBackendFilter() {
    let fechaBusqueda: Array<string> = [];
    let fechaDesde = this.formSearch.get('fechaDesde')?.value;
    let fechaHasta = this.formSearch.get('fechaHasta')?.value;

    if (fechaDesde) {
      let formatDateDesde = moment(new Date(fechaDesde)).format('YYYY-MM-DD');
      formatDateDesde = `gte(${formatDateDesde})`;
      fechaBusqueda.push(formatDateDesde);
    }
    if (fechaHasta) {
      let formatDateHasta = moment(
        new Date(this.formSearch.get('fechaHasta')?.value)
      ).format('YYYY-MM-DD');
      if (fechaDesde) {
        formatDateHasta = `and(lte(${formatDateHasta}))`;
      } else {
        formatDateHasta = `lte(${formatDateHasta})`;
      }
      fechaBusqueda.push(formatDateHasta);
    }

    const backendFilter: SolicitudReutilizacionFilterDTO = {
      estado: { id: this.formSearch.get('estado')?.value },
      tipoUsuario: this.formSearch.get('tipoUsuario')?.value,
      idUsuario: this.formSearch.get('idUsuario')?.value ? this.formSearch.get('idUsuario')?.value : undefined,
      usuarioSDDRCA: this.formSearch.get('usuarioSddr')?.value,
      tipoEnvase: { id: this.formSearch.get('tipoEnvase')?.value },
      subTipoEnvase: { id: this.formSearch.get('subtipoEnvase')?.value },
      loteEnvase: this.formSearch.get('loteEnvase')?.value ? this.formSearch.get('loteEnvase')?.value : undefined,
      usoEnvase: this.formSearch.get('usoEnvase')?.value,
      autoFacturada: this.formSearch.get('autofacturada')?.value,
      fechaSolicitud: fechaBusqueda
    };

    return backendFilter;
  }
  confirmacionAutofactura(): void {
    Swal
      .fire({
        text: '¿Desea crear prefactura de las solicitudes seleccionadas en la tabla?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.crearAutofactura();
        }
      });
  }

  crearAutofactura() {
    const list = this.gridArray.filter((x) => x.checked === true);

    if (list.length == 0) {
      this.snackBarSrv.showSnackBar(
        `Debe seleccionar al menos una solicitud para generar una prefactura`,
        'error'
      );
      return;
    }
    this.spinnerSrv.loadingShow();
    this.solicitudesReutilizacionCtrlSrv.crearAutofactura(list)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha generado la prefactura correctamente.',
            'success'
          );
          this.buscar();
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          let message = err.error?.arguments[0] ?? 'Ha ocurrido un error al generar la prefactura';
          this.snackBarSrv.showSnackBar(message, 'error');
        },
      });
  }
  confirmacionBorrar(element?: any): void {
    Swal
      .fire({
        text: '¿Desea eliminar la solicitud de reutilización?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.borrarSolicitud(element?.id!);
        }
      });
  }
  borrarSolicitud(idSolicitud: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudesReutilizacionCtrlSrv.deleteByIdSolicitudReutilizacion(idSolicitud)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha borrado la solicud de reutilización se ha realizado correctamente.',
            'success'
          );
          this.buscar();
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al borrar la solicitude de reutilización.',
            'error'
          );
        },
      });

  }
  confirmacionEnviar(element?: any): void {
    Swal
      .fire({
        text: '¿Desea enviar la solictud de reutilización?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.enviarSolicitud(element?.id!);
        }
      });
  }
  enviarSolicitud(idSolicitud: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudesReutilizacionCtrlSrv.enviarSolicitud(idSolicitud)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha enviado la solicitud de reutilizaicón correctamente.',
            'success'
          );
          this.buscar();
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al enviar la solicitude de reutilización.',
            'error'
          );
        },
      });
  }
  confirmacionAceptar(element?: any): void {
    Swal
      .fire({
        text: '¿Desea aceptar la solictud de reutilización?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.aceptarSolicitud(element?.id!);
        }
      });
  }
  aceptarSolicitud(idSolicitud: number): void {
    console.log('id', idSolicitud)
    this.spinnerSrv.loadingShow();
    this.solicitudesReutilizacionCtrlSrv.aceptarSolicitud(idSolicitud)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se aceptado la solicitud de reutilización correctamente.',
            'success'
          );
          this.buscar();
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al aceptar la solicitude de reutilización.',
            'error'
          );
        },
      });
  }
  confirmacionRechazar(element?: any): void {
    Swal
      .fire({
        text: '¿Desea rechazar la solictud de reutilización?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.rechazarSolicitud(element?.id!);
        }
      });
  }
  rechazarSolicitud(idSolicitud: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudesReutilizacionCtrlSrv.rechazarSolicitud(idSolicitud)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se rechazado la solicitud de reutilización correctamente.',
            'success'
          );
          this.buscar();
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al rechazar la solicitude de reutilización.',
            'error'
          );
        },
      });
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }
  canCrearAutofactura(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion', 'create-autofactura');
  }
  canCrearSolicitud(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion', 'create');
  }
  canExportar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion', 'update-exportar');
  }
  canEditar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion-detalle', 'edit');
  }
  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

}
