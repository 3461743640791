import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  FacturaEstadoDTO,
  FacturaDTO,
  FacturaPoseedorEstadosControllerService,
  FacturaPtoRecogidaFilterDTO,
  GestorPtoRecogidaControllerService,
  GestorPtoRecogidaFilterDTO,
  FacturaPtoRecogidaControllerService,
  PageDTOFacturaPtoRecogidaDTO
} from 'api-rest';

import { CPInvoiceNewDocumentDialogComponent } from '../collection-points-invoice-document-dialog/cp-invoice-document-dialog.component';
import { DocumentService } from '../../../services/document.service';
import moment from 'moment';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cp-self-invoices-search',
  templateUrl: './cp-self-invoices-search.component.html',
  styleUrls: ['./cp-self-invoices-search.component.scss'],
})
export class CPSelfInvoicesSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  gestorPtoRecogidaArray: any[] = [];
  estadoArray: any[] = [];

  headArray: any = [
    {
      Head: 'ID. Gestor Punto Recogida',
      FieldName: 'gestorPtoRecogida',
      Attribute: 'id',
    },
    {
      Head: 'Gestor Punto Recogida',
      FieldName: 'gestorPtoRecogida',
      Attribute: 'razonSocial',
    },
    {
      Head: 'Documento',
      FieldName: 'gestorPtoRecogida',
      Attribute: 'codigoDocumento',
    },
    {
      Head: 'NIMA del punto recogida',
      FieldName: 'gestorPtoRecogida',
      Attribute: 'nima',
    },
    {
      Head: 'Número de prefactura',
      FieldName: 'id',
      renderValue: (item: FacturaDTO) =>
        item.fechaAutofactura !== null ? item.id : '',
    },
    {
      Head: 'Fecha de prefactura',
      FieldName: 'fechaMAutofactura',
    },
    {
      Head: 'Número factura punto recogida ',
      FieldName: 'numeroFactura',
    },
    {
      Head: 'Fecha factura',
      FieldName: 'fechaMFactura',
    },
    {
      Head: 'Estado ',
      FieldName: 'estado',
      Attribute: 'descripcionCorta',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Eliminar registro',
          show: { params: 'canAccessDelete' },
        },
        {
          nameButton: 'add_invoice',
          icon: 'upload_file',
          toolTip: 'Adjuntar factura',
          show: { params: 'canAccessUpInvoice' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'donwload_invoice',
              name: 'Descargar factura',
              show: { params: 'canAccessDonwloadInvoice' },
            },
            {
              nameButton: 'donwload_self_invoice',
              name: 'Descargar prefactura',
              show: { params: 'canAccessDonwloadSelfInvoice' },
            },
            {
              nameButton: 'accept_invoice',
              name: 'Aceptar factura',
              show: { params: 'canAccessAcceptInvoice' },
            },
          ],
        },
      ],
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService | any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private facturaPtoRecogidaControllerService: FacturaPtoRecogidaControllerService,
    private facturaPoseedorEstadosControllerService: FacturaPoseedorEstadosControllerService,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService,
    private downloadService: DownloadService,
    private documentService: DocumentService,
    private snackBarSrv: SnackbarService,
    private datePipe: DatePipe
  ) {
    this.searchForm = this.formBuilder.group({
      gestorPtoRecogida: [{ value: null, disabled: false }],
      idGestorPtoRecogida: [{ value: null, disabled: false }],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      nima_GestorPtoRecogida: [{ value: null, disabled: false }],
      numero_autoFactura: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      fecha_autoFactura: [{ value: null, disabled: false }],
      numero_factura: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      fecha_factura: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.comboEstados();
    this.getGestorPtoRecogida();
  }

  checked(event: any) {}

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.facturaPtoRecogidaControllerService
      .exportCSV1(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_Factura_PtoRecogida.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  getGestorPtoRecogida() {
    if (this.authService.user?.roleEntities?.length > 0) {
      const gestorPtoRecogidaIds = this.authService.user?.roleEntities
        .filter(
          (gestorPtoRecogidaRol: any) =>
            gestorPtoRecogidaRol.type === 'GESTOR_PUNTO_RECOGIDA'
        )
        .map(
          (gestorPtoRecogidaRol: any) => gestorPtoRecogidaRol.entity as number
        );
      const filter: GestorPtoRecogidaFilterDTO = {
        id: gestorPtoRecogidaIds,
      };
      this.spinnerSrv.loadingShow();
      this.gestorPtoRecogidaControllerService
        .findAllGestorPtoRecogida(filter, { page: 0, size: 100 })
        .subscribe({
          next: (response: any) => {
            this.gestorPtoRecogidaArray = response?.datos;
            this.spinnerSrv.loadingHide();
          },
          error: (err) => {
            console.log(err);
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  private getBackendFilter() {
    let ptoRecogidaIds;
    if (
      this.authService.user?.roleEntities?.length > 0 &&
      this.canViewResource('PR-GESTOR')
    ) {
      ptoRecogidaIds = this.authService.user?.roleEntities
        .filter(
          (ptoRecogidaRol: any) =>
            ptoRecogidaRol.type === 'GESTOR_PUNTO_RECOGIDA'
        )
        .map((ptoRecogidaRol: any) => ptoRecogidaRol.entity as number);
    }
    const {
      gestorPtoRecogida,
      idGestorPtoRecogida,
      codigo_documento,
      razon_social,
      nima_GestorPtoRecogida,
      numero_autoFactura,
      fecha_autoFactura,
      numero_factura,
      fecha_factura,
      estado,
    } = this.searchForm.controls;

    const formatDate = (date: any) => {
      if (date && date.value) {
        return moment(new Date(date.value)).format('DD/MM/YYYY');
      }
      return undefined;
    };

    const backendFilter: FacturaPtoRecogidaFilterDTO = {
      gestorPtoRecogida: {
        id:
          idGestorPtoRecogida.value != null
            ? idGestorPtoRecogida.value
            : (gestorPtoRecogida.value != null && gestorPtoRecogida.value.length != 0)
            ? gestorPtoRecogida.value
            : ptoRecogidaIds,
        codigoDocumento: codigo_documento?.value,
        razonSocial:
          razon_social?.value != null ? [`ci(${razon_social?.value})`] : [],
        nima: nima_GestorPtoRecogida?.value,
      },
      id: numero_autoFactura?.value,
      fechaAutofactura: formatDate(fecha_autoFactura),
      numeroFactura: numero_factura?.value,
      fechaFactura: formatDate(fecha_factura),
      estado: estado?.value,
      origen: 'PUNTOSRECOGIDA'
    };

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.facturaPtoRecogidaControllerService
      .findAllFacturasPtoRecogida(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOFacturaPtoRecogidaDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos) ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'add_invoice') {
      this.openModalUploadInvoice(element);
    } else if (element.nameButton == 'donwload_invoice') {
      this.descargar(element.documento);
    } else if (element.nameButton == 'donwload_self_invoice') {
      this.descargar(element.documentoAutofactura);
    } else if (element.nameButton == 'accept_invoice') {
      this.confirmacionCambioEstado(element.id, 3);
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la prefactura?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarInvoices(element);
      }
    });
  }

  eliminarInvoices(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.facturaPtoRecogidaControllerService.deleteById(element.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `El registro de factura se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  comboEstados() {
    this.facturaPoseedorEstadosControllerService.findAll11().subscribe({
      next: (data: Array<FacturaEstadoDTO>) => {
        this.estadoArray = data!;
      },
      error: () => {},
    });
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  openModalUploadInvoice(element?: any): void {
    const dialogRef = this.dialog.open(CPInvoiceNewDocumentDialogComponent, {
      width: '650px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        itemOpen: element,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchData();
      }
    });
  }

  descargar(documento: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(documento.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  confirmacionCambioEstado(id?: any, estadoId?: any): void {
    Swal.fire({
      text: `¿Desea cambiar el estado del registro por Aceptada por Envalora?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.actualizarEstado(id, estadoId);
      }
    });
  }

  actualizarEstado(id?: any, estadoId?: any) {
    if (id && estadoId) {
      this.spinnerSrv.loadingShow();
      this.facturaPtoRecogidaControllerService.actualizarEstado(id, estadoId).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `El registro de factura se ha actualizado correctamente`,
            'success'
          );
          this.searchData();
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canAccessExport(): boolean {
    return this.authService.can('ptosrecogida-facturas', 'update-exportar');
  }

  canAccessAll(list: any) {
    const resultDelete = this.authService.can(
      'ptosrecogida-facturas',
      'delete'
    );
    const resultUpFactura = this.authService.can(
      'ptosrecogida-facturas',
      'update'
    );
    const resultDownloadAutofactura = this.authService.can(
      'ptosrecogida-facturas',
      'download-autofactura'
    );
    const resultDownloadFactura = this.authService.can(
      'ptosrecogida-facturas',
      'download-factura'
    );
    const resultUpdateAccept = this.authService.can(
      'ptosrecogida-facturas',
      'update-accept'
    );

    const listPermission = list.map((objeto: any) => {
      const estado1 = objeto.estado && objeto.estado.id === 1;
      const estado2 = objeto.estado && objeto.estado.id === 2;
      const estado3 = objeto.estado && objeto.estado.id === 3;

      let formattedDate =
        objeto.fechaFactura != null
          ? this.datePipe.transform(objeto.fechaFactura, 'dd/MM/yyyy')
          : null;
      objeto.fechaMFactura = formattedDate;

      let formattedDateM =
        objeto.fechaAutofactura != null
          ? this.datePipe.transform(objeto.fechaAutofactura, 'dd/MM/yyyy')
          : null;
      objeto.fechaMAutofactura = formattedDateM;

      return {
        ...objeto,
        canAccessDelete: resultDelete && estado1,
        canAccessUpInvoice: resultUpFactura && estado1,
        canAccessDonwloadInvoice: resultDownloadFactura && (estado2 || estado3),
        canAccessDonwloadSelfInvoice: resultDownloadAutofactura,
        canAccessAcceptInvoice: resultUpdateAccept && estado2,
      };
    });

    return listPermission;
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
