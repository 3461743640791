import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Combo2 } from '../../../models/combo';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  DocumControllerService,
  TipoDocumentoControllerService,
  TipoDocumentoModel,
} from 'api-rest';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'app-collection-points-documents-dialog',
  templateUrl: './collection-points-documents-dialog.component.html',
  styleUrls: ['./collection-points-documents-dialog.component.scss'],
})
export class CollectionPointsDocumentsDialogComponent implements OnInit {
  formGroup!: FormGroup;
  puntoRecogidaInfo!: any;

  tiposDocumento!: Array<TipoDocumentoModel>;
  adjuntosBlob: Array<File> = [];
  botonEstado: boolean = false;
  flagTooltip: boolean = false;

  containerPuntosRecogida: string = this.environment.documentContainerCollectionPoints;

  constructor(
    @Inject('environment') private environment: any,
    public dialogRef: MatDialogRef<CollectionPointsDocumentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private tipoDocumentoControllerService: TipoDocumentoControllerService,
    private documControllerSrv: DocumControllerService,
    public spinnerSrv: SpinnerService,
    private snackbarSrv: SnackbarService
  ) {
    this.puntoRecogidaInfo = this.data.puntoRecogidaInfo;

    this.formGroup = this.formBuilder.group({
      tipoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      descripcion: [{ value: null, disabled: false }, Validators.maxLength(255)],
    });
  }

  ngOnInit(): void {
    this.cargarDocumentos();
    this.cargarTooltip();
  }

  cargarTooltip() {
    this.formGroup
      .get('tipoDocumento')
      ?.valueChanges.subscribe((value: any) => {
        if (value == 4) {
          this.flagTooltip = true;
        } else {
          this.flagTooltip = false;
        }
      });
  }

  cargarDocumentos(): void {
    this.tipoDocumentoControllerService.listarTipoDocumentos().subscribe({
      next: (response: any) => {
        // Filtrar solo los documentos con ids 1, 4 y 5 (contrato, otros, adenda)
        this.tiposDocumento = response.filter((doc: TipoDocumentoModel) => [1, 4, 5].includes(Number(doc.id)));
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  guardar(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {
      if (this.adjuntosBlob?.length > 0) {
        let id = this.puntoRecogidaInfo.id! ?? null;
        let typeDoc = this.formGroup.controls['tipoDocumento'].value! ?? null;
        let docDesc = this.formGroup.controls['descripcion'].value! ?? '';
        this.setBotonEstado(true);
        this.spinnerSrv.loadingShow();
        this.documControllerSrv
          .uploadDoc(
            this.containerPuntosRecogida,
            typeDoc,
            id,
            'punto_recogida',
            this.adjuntosBlob[0]?.name,
            this.adjuntosBlob[0],
            docDesc
          )
          .subscribe({
            next: () => {
              this.spinnerSrv.loadingHide();
              this.snackbarSrv.showSnackBar(
                'Documento subido satisfactoriamente',
                'success'
              );
              this.dialogRef.close(true);
            },
            error: (err) => {
              this.setBotonEstado(false);
              console.log(err);
              this.spinnerSrv.loadingHide();
              this.snackbarSrv.showSnackBar(
                'Error al subir documento',
                'error'
              );
            },
            complete: () => {
              this.spinnerSrv.loadingHide();
            },
          });
      }
    }
  }
  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  attachAdjunto(archivo: File | null): void {
    if (archivo != null) {
      if (archivo.size === 0) {
        this.snackbarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackbarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.adjuntosBlob.push(archivo);
      }
    }
  }

  setBotonEstado(estado: boolean) {
    return (this.botonEstado = estado);
  }
}
