import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { ComboService, Combo } from '../../../services/combo.service';
import { DownloadService } from '../../../services/download.service';
import {
  TarifaPoseedoresControllerService,
  CodigoLerControllerService,
  TarifaPoseedoresFilterDTO,
  PageDTOTarifaPoseedoresDTO,
  CodigoLerDTO,
  CodigoLerFilterDTO,
  PageDTOCodigoLerDTO,
  DescripcionEnvaseDTO,
} from 'api-rest';
import { OwnerRateDialogComponent } from '../owner-rate-dialog/owner-rate-dialog.component';
import { SpinnerService } from '../../../services/spinner.service';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';

@Component({
  selector: 'app-owner-rate',
  templateUrl: './owner-rate.component.html',
  styleUrls: ['./owner-rate.component.scss'],
})
export class OwnerRateComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  codigoLerArray: Array<CodigoLerDTO> = [];
  descripcionArray: Array<DescripcionEnvaseDTO> = [];

  headArray: any = [
    {
      Head: 'Año',
      FieldName: 'anio',
    },
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'codigoLer',
      renderValue: (item: any) =>
        `${item.codigoLer.tipoCodigo} ${item.codigoLer.descripcion}`,
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'Peligrosidad',
      FieldName: 'peligrosidad',
    },
    {
      Head: '€/T',
      FieldName: 'valorTarifa',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item.valorTarifa),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        { nameButton: 'view', icon: 'open_in_new', toolTip: 'Editar' },
        { nameButton: 'delete', icon: 'delete', toolTip: 'Borrar' },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;
  defaultFilter: boolean = true;
  codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?poseedores=true';

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public comboService: ComboService,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private codigoLerControllerService: CodigoLerControllerService,
    private TarifaPoseedoresControllerService: TarifaPoseedoresControllerService,
    private snackBarSrv: SnackbarService,
    private formatCurrencyPipe: FormatCurrencyPipe
  ) {
    this.searchForm = this.formBuilder.group({
      year: [
        { value: new Date().getFullYear(), disabled: false },
        { validators: Validators.compose([this.customValidator()]) },
      ],
      codigoLer: [{ value: null, disabled: false }],
      descripcion: [{ value: null, disabled: false }],
      grupoEnvase: [{ value: null, disabled: false }],
    });
  }

  ngOnInit(): void {
    this.comboCodigoLer();
  }

  checked(event: any) {}

  renderPage(event: any) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.TarifaPoseedoresControllerService.exportCSV(
      backendFilter,
      'response'
    ).subscribe((res) => {
      const filename = this.downloadService.getFileName(
        res.headers,
        'Exportacion_Tarifa_Poseedores.csv'
      );
      this.downloadService.downloadCSV(res.body!, filename!);
    });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.searchForm.get('codigoLer') as FormControl;
      }
      case 'descripcion': {
        return this.searchForm.get('descripcion') as FormControl;
      }
      case 'grupoEnvase': {
        return this.searchForm.get('grupoEnvase') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  customValidator() {
    return (control: { value: any }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (/^\d{4}$/.test(value)) {
        const intValue = parseInt(value, 10);
        if (intValue >= 1900 && intValue <= 2500) {
          return null;
        }
      }
      return { invalid: true };
    };
  }

  openModalOwner(element?: any): void {
    const dialogRef = this.dialog.open(OwnerRateDialogComponent, {
      width: '960px',
      data: {
        itemOpen: element,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearSearcher();
        this.searchData();
      }
    });
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    const backendFilter: TarifaPoseedoresFilterDTO = {
      anio: this.searchForm.get('year')?.value,
      codigoLer:
        this.searchForm.get('codigoLer')?.value != null &&
        this.searchForm.get('codigoLer')?.value != ''
          ? { id: this.searchForm.get('codigoLer')?.value?.id }
          : {},
      descripcionEnvase: this.searchForm.get('descripcion')?.value
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.TarifaPoseedoresControllerService.findAll(backendFilter, {
      page: page,
      size: size,
      sort: ['anio,asc'],
    }).subscribe({
      next: (data: PageDTOTarifaPoseedoresDTO) => {
        if (data) {
          this.spinnerSrv.loadingHide();
          this.flagSearch = data.datos?.length != 0 ? true : false;
          this.gridArray =
            data.datos?.map((obj: any) => {
              return {
                ...obj,
                peligrosidad: obj.codigoLer?.peligrosidad ? 'SI' : 'NO',
              };
            }) ?? [];
          this.recordsTotal = data.paginacion?.totalElements ?? 0;
          this.totalSearch = this.gridArray.length;
        }
      },
      error: (error: any) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openModalOwner(element);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la tarifa?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarTarifa(element);
      }
    });
  }

  eliminarTarifa(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.TarifaPoseedoresControllerService.deleteById(element.id).subscribe({
        next: (value: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `La tarifa se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar la tarifa',
            'error'
          );
        },
      });
    }
  }

  comboCodigoLer() {
    const backendFilter: CodigoLerFilterDTO = {};
    this.codigoLerControllerService
      .findAll4(backendFilter, { page: 0, size: 100 })
      .subscribe({
        next: (data: PageDTOCodigoLerDTO) => {
          this.codigoLerArray = data?.datos!;
        },
        error: () => {},
      });
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }

  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
}
