<div class="modal-content">

    <div class="modal-header">
      <h3 class="modal-title font" *ngIf="newUser; else editUserTitle">Nuevo Usuario</h3>
      <ng-template #editUserTitle>
        <h3 class="modal-title font">Editar Usuario</h3>
      </ng-template>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
      <div class="title-name mb-3" style="font-size: 1.3rem; font-weight: 700;">{{ formGroup.value?.firstName }} {{ formGroup.value?.lastName }}</div>

      <form class="form-search py-3" [formGroup]="formGroup">
        <div class="row form">
          <div class="col-sm flex-grow-1">
            <label for="firstName">Nombre <span class="oblig">*</span></label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input  type="text" matInput formControlName="firstName" id="firstName" placeholder="Nombre" value="" maxlength="255">
                <mat-error *ngIf="controlHasError('firstName', 'required')">Campo obligatorio</mat-error>
                <mat-error *ngIf="controlHasError( 'firstName', 'maxlength')">Máximo 255 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm flex-grow-1">
            <label for="lastName">Apellidos <span class="oblig">*</span></label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="lastName" id="lastName" placeholder="Apellidos" value="" maxlength="255">
                <mat-error *ngIf="controlHasError('lastName', 'required')">Campo obligatorio</mat-error>
                <mat-error *ngIf="controlHasError( 'lastName', 'maxlength')">Máximo 255 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row form">
          <div class="col-sm flex-grow-1">
            <label for="email">Email <span class="oblig">*</span></label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input  type="text" matInput formControlName="email" id="email" placeholder="Email" value="" maxlength="255">
                <mat-error *ngIf="controlHasError('email', 'required')">Campo obligatorio</mat-error>
                <mat-error *ngIf="controlHasError( 'email', 'maxlength')">Máximo 255 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm flex-grow-1">
            <label for="enabled">Activo</label>
            <div class="custom-mat-field">
              <mat-checkbox formControlName="enabled" id="enabled"
                  color="primary">
                </mat-checkbox>
            </div>
          </div>
        </div>
      </form>
      <div class="title-name pt-2" style="font-size: 1.3rem; font-weight: 700;">Roles <span class="oblig">*</span></div>
      <form class="form-search py-3" [formGroup]="userRoleEntityFormGroup">
        <div class="row form align-items-center">
          <div class="width-28 col-sm flex-grow-1">
            <label for="entityType">Tipo de Usuario <span class="oblig">*</span></label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <mat-select formControlName="entityType" id="entityType" placeholder="-- Tipo de usuario --" >
                  <mat-option *ngFor="let element of userTypes" [value]="element.id">
                    {{ separarCamelCase( element.name )}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="controlHasErrorSubForm('entityType', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class=" width-28 col-sm flex-grow-1">
            <label for="userRole">Rol <span class="oblig">*</span></label>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <mat-select formControlName="role" id="userRole" placeholder="-- Rol de usuario --">
                  <mat-option *ngFor="let role of rolesFiltered" [value]="role">
                    {{ role.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="controlHasErrorSubForm('role', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="width-28 col-sm flex-grow-1 pb-4">
            <label for="idEntidadAsociada">Entidad asociada</label>
            <div class="custom-mat-field">
              <lib-ge-select-pagination #entidadAsociadaSelector class="lib-ge-select"  descripcion="razonSocial"
                [endPointFilter]="entidadAdociadaEndpoint" objQueryName="razonSocial" placeHolder="-- Entidad asociada --"
                [formControlNameSelect] = "getFormControl(userRoleEntityFormGroup,  'entity')" [modelSelected]="this.formGroup.value.entidadAsociada"
                [updateList]="updateList" (filteredList)="onFilteredList($event)">
              </lib-ge-select-pagination>
              <mat-error *ngIf="controlHasErrorSubForm('entity', 'required')">Campo obligatorio</mat-error>
            </div>
          </div>
          <div class="width-15 col-sm flex-grow-1 pt-1">
            <button type="button" (click)="addUserRoleEntity()" class="btn btn-primary">Agregar</button>
          </div>
        </div>
      </form>

      <div class="row form">
        <div class="col-sm-12">
          <lib-ge-table [headArray]="headArray" [gridArray]="userRolesEntities" (actionButton)="action($event)"></lib-ge-table>
        </div>
      </div>

    </div>
    <div class="modal-footer actions">
      <div  class="note"><span *ngIf="editMode">* Campos Obligatorios</span></div>
      <div class="button-group">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
          aria-label="Cancelar">Cancelar</button>
          <button *ngIf="!editMode" type="button" class="btn btn-primary" data-dismiss="modal" (click)="habilitarEdicion();"
          aria-label="Guardar">Editar</button>
          <button *ngIf="editMode" type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
          aria-label="Guardar">Guardar</button>
      </div>
    </div>
  </div>
