import { Component, ViewChild, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleInjectorService } from '../../../core/moduleinjector.service';
import {
  EmpusaMicroApp,
  EmpusaAuthenticationService,
  EmpusaThemeService,
} from '@empusa/empusa-core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from '../../../translations/paginator.i18n';
import { MatDialog } from '@angular/material/dialog';
import { ProfileComponent } from '../../profile/profile.component';
import { arrayBufferToBase64 } from '../../../core/util';
import { THEMES } from '../../../theme.config';
import { SelectableLanguaje } from '../../../model/selectable-languaje.interface';
import { TermsOfUseComponent } from '../../terms-of-use/terms-of-use.component';
import { EmpusaPortalAuthService } from 'src/app/services/auth.service';
import {
  PoseedorControllerService,
  PuntoRecogidaControllerService,
  GestorPtoRecogidaControllerService,
  AdheridoControllerService,
  BusquedaAdheridoDTO,
  GestoresResiduosControllerService,
  ComunicacionControllerService,
  ComunicacionFilterDTO,
  ComunicacionEstadoControllerService,
  ComunicacionEstadoDTO
} from 'api-rest';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  providers: [ModuleInjectorService],
})
export class HomePage implements OnInit, OnDestroy {
  site: string;

  navbarTarget: string =
    window.innerWidth <= 1200 ? '#navbarSupportedContent' : '';
  /** list of modules availables */
  modules: EmpusaMicroApp[] = [];

  /** the current page selected */
  currentDynamicPage: string = '';

  /** the current selected lang */
  currentLang: string | undefined;

  bmsGrafana: string = this.environment.grafana;
  includeGrafana: boolean = false;

  @ViewChild('userPicture') userPictureImg: any;
  havePhoto: boolean = false;

  loading: boolean = true;
  selectableThemes: string[] = [];
  selectedTheme: string | null;
  privacyPolicyUrl: string | undefined;
  termsOfUse: boolean | undefined = false;
  selectableLanguajes: any;
  entityRoles: any[] = [];
  adheridosRoles: any[] = [];
  gestoresRoles: any[] = [];
  poseedoresRoles: any[] = [];
  puntosRecogidaRoles: any[] = [];
  gestorPuntosRecogidaRoles: any[] = [];
  comunicacionesPendientes: number = 0;
  intervalId: any;

  constructor(
    private router: Router,
    private matPaginator: MatPaginatorIntl,
    private dialog: MatDialog,
    private paginator: PaginatorI18n,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService,
    private empusaThemeService: EmpusaThemeService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private translate: TranslateService,
    private empusaPortalAuthervice: EmpusaPortalAuthService,
    private adheridoService: AdheridoControllerService,
    @Inject('environment') private environment: any,
    private gestoresResiduosControllerService: GestoresResiduosControllerService,
    private poseedorControllerService: PoseedorControllerService,
    private puntoRecogidaControllerService: PuntoRecogidaControllerService,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService,
    private comunicacionControllerSrv: ComunicacionControllerService,
    private comunicacionEstadoControllerSrv: ComunicacionEstadoControllerService
  ) {
    /**  Site name */
    this.site = environment.site;

    /** Privacy url and terms of use*/
    this.privacyPolicyUrl = environment.privacyPolicyUrl;
    this.termsOfUse = environment.termsOfUse;

    /**Theme manager */
    this.selectableThemes = environment.selectableThemes;
    this.empusaThemeService.loadThemes(THEMES, this.environment.theme);
    if (this.selectableThemes && this.selectableThemes.length > 1)
      this.selectedTheme = this.empusaThemeService.setTheme(
        this.empusaThemeService.retrieveUserTheme()
      );
    else
      this.selectedTheme = this.empusaThemeService.setTheme(
        this.environment.theme
      );

    /** Languajes */
    this.currentLang = undefined;
    this.selectableLanguajes = this.environment.selectableLanguajes;
    this.selectableLanguajes.forEach((languaje: SelectableLanguaje) => {
      iconRegistry.addSvgIcon(
        languaje.svgIconName,
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/img/flags/' + languaje.svgIconFile
        )
      );
      if (!this.currentLang || languaje.locale == translate.currentLang) {
        this.currentLang = translate.currentLang;
      }
    });

    this.translate.onLangChange.subscribe((change) => {
      this.paginator.refresh(this.matPaginator);
      this.matPaginator.changes.next();
      this.currentLang = change.lang;
    });

    /** User photo */
    this.authService.isUserPhotoLoaded().subscribe((done) => {
      this.havePhoto = done;
      if (done) {
        this.userPictureImg.nativeElement.src =
          'data:image/jpeg;base64,' +
          arrayBufferToBase64(authService.user?.photo);
      }
    });

    this.router.navigate(['/']);

    /** Initialize permissions and access */
    let allModules: EmpusaMicroApp[] = (<any>window).empusa.modules;

    this.empusaPortalAuthervice.checkModulesPermissions(
      allModules,
      this.getCurrentPath()
    );

    this.empusaPortalAuthervice.includeGrafana.subscribe((value: boolean) => {
      this.includeGrafana = value;
    });

    this.empusaPortalAuthervice.loading.subscribe((value: boolean) => {
      this.loading = value;
    });

    this.empusaPortalAuthervice.modulesInMenu.subscribe(
      (modules: EmpusaMicroApp[]) => {
        this.modules = modules;
      }
    );

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1200) {
        this.navbarTarget = '#navbarSupportedContent';
      } else {
        this.navbarTarget = '';
      }
    });

    this.empusaPortalAuthervice.getTempIdDataObservable.subscribe(
      (value: any) => {
        console.log('viewsFinal', value);
        this.entityRoles = value.entityRoles;
        if (this.entityRoles) {
          this.adheridosRoles = this.entityRoles.filter(
            (entityRol) => entityRol.type == 'ADHERIDO' && entityRol.entity
          );
          this.gestoresRoles = this.entityRoles.filter(
            (entityRol) =>
              entityRol.type == 'GESTOR_POSEEDOR' && entityRol.entity
          );

          this.poseedoresRoles = this.entityRoles.filter(
            (entityRol) => entityRol.type == 'POSEEDOR' && entityRol.entity
          );

          this.puntosRecogidaRoles = this.entityRoles.filter(
            (entityRol) =>
              entityRol.type == 'PUNTO_RECOGIDA' && entityRol.entity
          );

          this.gestorPuntosRecogidaRoles = this.entityRoles.filter(
            (entityRol) =>
              entityRol.type == 'GESTOR_PUNTO_RECOGIDA' && entityRol.entity
          );

          if (this.adheridosRoles?.length > 0) {
            const adheridoIds = this.adheridosRoles.map(
              (adheridoRol) => adheridoRol.entity as number
            );
            const filter: BusquedaAdheridoDTO = {
              id: adheridoIds,
            };
            adheridoService
              .listarAdheridos({ page: 0, size: 100 }, filter)
              .subscribe((adheridos: any) => {
                if (adheridos.content) {
                  adheridos.content.forEach((adherido: any) => {
                    const adheridoRolObject = this.adheridosRoles.find(
                      (adheridoRol) =>
                        adheridoRol.entity == adherido.id &&
                        adheridoRol.role === 'AD-ADHERIDO'
                    );
                    adheridoRolObject.entityObject = adherido;
                  });
                }
              });
          }
          if (this.gestoresRoles?.length > 0) {
            const gestorIds = this.gestoresRoles.map(
              (dataRol) => dataRol.entity as number
            );
            const filter: any = {
              id: [gestorIds],
            };
            gestoresResiduosControllerService
              .findAll8(filter, { page: 0, size: 100 })
              .subscribe((gestor: any) => {
                if (gestor.datos) {
                  gestor.datos.forEach((gestor: any) => {
                    const gestorRolObject = this.gestoresRoles.find(
                      (gestorRol) =>
                        gestorRol.entity == gestor.id &&
                        gestorRol.role === 'PO-GESTOR'
                    );
                    gestorRolObject.entityObject = gestor;
                  });
                }
              });
          }
          if (this.poseedoresRoles?.length > 0) {
            const poseedorIds = this.poseedoresRoles.map(
              (dataRol) => dataRol.entity as number
            );
            const filter: any = {
              id: [poseedorIds],
            };
            poseedorControllerService
              .findAll5(filter, { page: 0, size: 100 })
              .subscribe((poseedor: any) => {
                if (poseedor.datos) {
                  poseedor.datos.forEach((poseedor: any) => {
                    const poseedorRolObject = this.poseedoresRoles.find(
                      (poseedorRol) =>
                        poseedorRol.entity == poseedor.id &&
                        poseedorRol.role === 'PO-POSEEDOR'
                    );
                    poseedorRolObject.entityObject = poseedor;
                  });
                }
              });
          }
          if (this.puntosRecogidaRoles?.length > 0) {
            const puntosRecogidaIds = this.puntosRecogidaRoles.map(
              (dataRol) => dataRol.entity as number
            );
            const filter: any = {
              id: [puntosRecogidaIds],
            };
            puntoRecogidaControllerService
              .findAllPtoRecogida(filter, { page: 0, size: 100 })
              .subscribe((puntosRecogida: any) => {
                if (puntosRecogida.datos) {
                  puntosRecogida.datos.forEach((puntosRecogida: any) => {
                    const puntosRecogidaRolObject =
                      this.puntosRecogidaRoles.find(
                        (puntosRecogidaRol) =>
                          puntosRecogidaRol.entity == puntosRecogida.id &&
                          puntosRecogidaRol.role === 'PR-PUNTORECOGIDA'
                      );
                    puntosRecogidaRolObject.entityObject = puntosRecogida;
                  });
                }
              });
          }
          if (this.gestorPuntosRecogidaRoles?.length > 0) {
            const gestorPuntosRecogidaIds = this.gestorPuntosRecogidaRoles.map(
              (dataRol) => dataRol.entity as number
            );
            const filter: any = {
              id: [gestorPuntosRecogidaIds],
            };
            gestorPtoRecogidaControllerService
              .findAllGestorPtoRecogida(filter, { page: 0, size: 100 })
              .subscribe((gestorPuntosRecogida: any) => {
                if (gestorPuntosRecogida.datos) {
                  gestorPuntosRecogida.datos.forEach(
                    (gestorPuntosRecogida: any) => {
                      const gestorPuntosRecogidaRolObject =
                        this.gestorPuntosRecogidaRoles.find(
                          (gestorPuntosRecogidaRol) =>
                            gestorPuntosRecogidaRol.entity ==
                            gestorPuntosRecogida.id &&
                            gestorPuntosRecogidaRol.role === 'PR-GESTOR'
                        );
                      gestorPuntosRecogidaRolObject.entityObject =
                        gestorPuntosRecogida;
                    }
                  );
                }
              });
          }
        }
      }
    );


  }
  ngOnInit(): void {
    this.obtenerComunicacionesPendientes();
    //Se actualiza el contador de comunicaciones cada 10 minutos
    this.intervalId = setInterval(() => {
      this.obtenerComunicacionesPendientes();
    }, 600000);
  }
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  obtenerComunicacionesPendientes() {
    //Se piden las comunicaciones pendientes
    const filtroComunicacion: ComunicacionFilterDTO = {
      email: this.authService.user?.userMail
    };
    if (this.canViewMenu('ADM-COMUNICACIONES')) {
      //El estado 4 es pendiente de envalora
      filtroComunicacion.estado = { id: ['4'] };
    } else {
      //El estado 3 es pendiente de usuario
      filtroComunicacion.estado = { id: ['3'] };
    }
    this.comunicacionControllerSrv.findAllComunicacion1(filtroComunicacion, { page: 0, size: 500 })
      .subscribe((comunicaciones: any) => {
        if (comunicaciones.datos) {
          this.comunicacionesPendientes = comunicaciones.datos?.length;
        }
      });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([uri]);
    });
  }

  canViewMenu(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canAccessAttachedApp(): boolean {
    return this.authService.can('adherido-menu', 'access-all');
  }

  canAccessAttachedAppSearch(): boolean {
    return this.authService.can('adherido', 'access-all');
  }

  canAccessPoseedoresAppMenu(): boolean {
    return this.authService.can('poseedores-menu', 'access');
  }

  canAccessPoseedoresSearchAccess(): boolean {
    return this.authService.can('poseedores', 'access-all');
  }

  canAccessPoseedoresGestorSearchAccess(): boolean {
    return this.authService.can('poseedores-gestor', 'access-all');
  }

  canAccessPoseedoresDiSearchAccess(): boolean {
    return this.authService.can('poseedores-di', 'access-all');
  }

  canAccessAdheridosDiSearchAccess(): boolean {
    return this.authService.can('adheridos-di', 'access-all');
  }

  canAccessPoseedoresAutoFacturaSearchAccess(): boolean {
    return this.authService.can('poseedores-facturas', 'access-all');
  }

  canAccessPackagingDeclaration(): boolean {
    return this.authService.can('dec_envases-menu', 'access-all');
  }

  canAccessAdministration(): boolean {
    return this.authService.can('administracion-menu', 'access-all');
  }

  canAccessCollectionPoints(): boolean {
    return this.authService.can('ptosrecogida-menu', 'access');
  }

  canAccessCollectionPointsSearch(): boolean {
    return this.authService.can('ptosrecogida', 'access-all');
  }

  canAccessCollectionPointsManagerSearch(): boolean {
    return this.authService.can('ptosrecogida-gestor', 'access-all');
  }

  canAccessCollectionPointsRequestsSearch(): boolean {
    return this.authService.can('ptosrecogida-solicitudes', 'access-all');
  }

  canAccessCPSelfInvoicesRequestsSearch(): boolean {
    return this.authService.can('ptosrecogida-facturas', 'access-all');
  }

  canAccessAcuerdoGestores(): boolean {
    return this.authService.can('gestores-acuerdo', 'access-all');
  }

  canAccessGestoresRegistrados(): boolean {
    return this.authService.can('poseedores-menu', 'access');
  }

  canAccessOpenSddrModule(){
    return this.authService.can('sddr-ca-solicitudes-reutilizacion', 'access-all')
    || this.authService.can('sddr-ca-solicitudes-retirada', 'access-all');
  }

  canAccessOpenSddrReuseRequest(){
    return this.authService.can('sddr-ca-solicitudes-reutilizacion', 'access-all');
  }

  canAccessOpenSddrWithDrawnRequest(){
    return this.authService.can('sddr-ca-solicitudes-retirada', 'access-all');
  }

  canAccessOpenSddrRemainRequest(){
    return this.authService.hasCurrentUserRole('SDDR-CA') && this.authService.can('sddr-ca-solicitudes-envase', 'access-all');
  }

  canAccessOpenSddrDiSearch(){
    return this.authService.can('sddr-ca-di', 'access-all');
  }

  canAccessOpenSddrAutofacturas(){
    return this.authService.can('sddr-ca-facturas', 'access-all');
  }

  getCurrentPath(): string {
    let currentRoute = this.router.url;
    return currentRoute.split('/')[1];
  }

  onChangeTheme(theme: string) {
    this.selectedTheme = theme;
    this.empusaThemeService.setTheme(theme);
  }

  onChangeLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('empusa.defaultLang', lang);
  }

  logout() {
    setTimeout(() => {
      this.authService._logout();
    }, 0);
    this.router.navigateByUrl('/');
  }

  profileUser() {
    let dialogAssign = this.dialog.open(ProfileComponent, {
      width: '680px',
    });
    dialogAssign.afterClosed().subscribe((result) => { });
  }

  openTermsOfUse() {
    const dialogRef = this.dialog.open(TermsOfUseComponent);

    dialogRef.afterClosed().subscribe((result) => { });
  }

  openComunications(): void {
    this.router.navigate(['/comunications/search']);
  }
  goInitialPage(): void{
    this.router.navigate(['/initial-page']);
  }
}
